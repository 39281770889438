import { api, handleResponse, handleError } from "./apiServices";

export const getServices = (token, data) =>
  api(token)
    .get(
      `/api/taxi/services/getServices?page=${data.currentPage}&limit=${data.limit}&search=${data.search}&sortField=${data.sortBy}&sortOrder=${data.sortOrder}`
    )
    .then(handleResponse)
    .catch(handleError);

export const deleteServices = (token, id) =>
  api(token)
    .put(`/api/taxi/services/deleteService/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const editService = (token, id, data) =>
  api(token)
    .put(`/api/taxi/services/editService/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const addService = (token, data) =>
  api(token)
    .post("/api/taxi/services/addService", data)
    .then(handleResponse)
    .catch(handleError);

export const deActivateService = (token, id, payload) =>
  api(token)
    .put(`/api/taxi/services/deActivateService/${id}`, payload)
    .then(handleResponse)
    .catch(handleError);

export const getServiceList = token =>
  api(token)
    .get("/api/taxi/station/getServiceList")
    .then(handleResponse)
    .catch(handleError);

export const getStation = (token, data) =>
  api(token)
    .get(
      `api/taxi/station/getTaxiStation?page=${data.currentPage}&limit=${data.limit}&search=${data.search}&sortField=${data.sortBy}&sortOrder=${data.sortOrder}`
    )
    .then(handleResponse)
    .catch(handleError);

export const deleteStation = (token, id) =>
  api(token)
    .put(`/api/taxi/station/deleteStation/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const deActivateStation = (token, id, body) =>
  api(token)
    .put(`/api/taxi/station/deActivateStation/${id}`, body)
    .then(handleResponse)
    .catch(handleError);

export const editStation = (token, id, data) =>
  api(token)
    .put(`/api/taxi/station/editTaxiStation/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const addStation = (token, data) =>
  api(token)
    .post(`/api/taxi/station/addStation`, data)
    .then(handleResponse)
    .catch(handleError);

export const getStationByService = (token, id) =>
  api(token)
    .get(`/api/taxi/station/getStationByService/${id}`)
    .then(handleResponse)
    .catch(handleError);
