import React, { useState } from "react";
import OtpInput from "react-otp-input";
import "./otp.css";
import AuthActions from "../../redux/auth/actions";
import { verifyTaxiMerchant } from "services/authServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const { success, error, login } = AuthActions;

export default function OtpModal({ email, onClose }) {
  const dispatch = useDispatch();

  console.log(email, "the email");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const onSubmitOtp = async () => {
    const data = {
      email: email,
      otp: otp
    };
    const result = await verifyTaxiMerchant(data);
    if (result.success) {
      dispatch(login(result.data));

      success(result.message);
      login(result.data);
      onClose();
    } else {
      error(result.message);
    }
  };
  return (
    <>
      <div className="container">
        <div className="card">
          <p>
            Please enter the OTP which received in your Register email id
            received OTP valid for only 5 min
          </p>
          <div className="otp-input-container">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              separator={<span>-</span>}
              isInputNum
              renderInput={props => <input {...props} className="otp-input" />}
            />
          </div>
        </div>
        <button onClick={onSubmitOtp}>Submit</button>
      </div>
    </>
  );
}
