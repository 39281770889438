import { useEffect, useState } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  Handle,
  MarkerType,
  Position,
  getBezierPath,
  useReactFlow,
} from "reactflow";

const ButtonEdge = ({ props, handleEdgeDelete, offsetX = 30, offsetY = 5 }) => {
  const { setEdges } = useReactFlow();
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    markerStart,
  } = props;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sourceX + offsetX,
    sourceY: sourceY + offsetY,
    sourcePosition,
    targetX: targetX + offsetX,
    targetY: targetY + offsetY,
    targetPosition,
  });

  useEffect(() => {
    setDetails(props);
  }, []);

  const [details, setDetails] = useState();

  const handleEdgeClick = () => {
    handleEdgeDelete(details);
  };

  return (
    <>
      <BaseEdge
        path={edgePath}
        interactionWidth={100}
        zIndex={9999}
        ariaLabel="asdadasdasdsdas"
        markerEnd={markerEnd}
        markerStart={markerStart}
        style={style}
      />
      <Handle type="source" position={Position.Top} />
      <Handle type="target" position={Position.Bottom} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",

            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <button className="edgebutton" onClick={handleEdgeClick}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default ButtonEdge;
