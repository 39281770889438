import React, { useEffect, useState } from "react";
import { loginBack, iconDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { Navigate, useNavigate } from "react-router-dom";
import NavigationActions from "redux/navigation/actions";
import AuthActions from "redux/auth/actions";
import { checkApi, loginApi } from "services/authServices";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import enhancer from "../../../enhancers/LoginFormEnhancer";
import Loader from "components/Loader";
import { useAsyncDebounce } from "react-table";

const { login, setUser } = AuthActions;

const {
  success,
  error,
  toggleOneTimeModal,
  fetching,
  toggleSubscriptionLoader
} = NavigationActions;

const Login = props => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    submitCount,
    isValid,
    token
  } = props;

  let navigate = useNavigate();

  const handleLogin = async e => {
    e.preventDefault();
    handleSubmit();
    console.log(isValid, "isValid");

    if (!isValid) {
      return;
    }

    fetching();
    setLoading(true);
    await loginApi(values).then(data => {
      if (data.success) {
        console.log("dataa", data.message);
        success(data.message);
        props.login(data.data);
        props.setUser(data.data);
        // props.history.push("/dashboard");
        navigate("/dashboard");
        setLoading(false);
      } else {
        error(data.message);
        setLoading(false);
      }
    });
  };

  // const { values, handleChange, handleBlur, errors, touched, submitCount } = props;

  const checkLogin = async () => {
    fetching();

    await checkApi(token).then(data => {
      if (data.success) {
        success();
        // props.history.push("/dashboard");
        navigate("/dashboard");
      } else {
        error();
      }
    });
  };

  // useEffect(() => {
  //     token !== null && checkLogin();
  // }, []);

  const loginContainer = {
    // backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={iconDemo} alt="icon" height="100px" />
        </div>
        <div className="login-title">Sign in to your account</div>
        <form className="pa-24">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Email"
            />
            {<Error field="email" />}
          </div>

          <div className="form-group">
            <label>Password</label>
            <div className="input-group mb-3">
              <input
                type={togglePassword ? "text" : "password"}
                className="form-control react-form-input"
                id="password"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
                placeholder="Password"
              />
              <div
                className="input-group-append "
                style={{ position: "absolute", right: 0, padding: "7px" }}
              >
                {togglePassword ? (
                  <VisibilityOff
                    style={{ fontSize: "20px" }}
                    onClick={() => setTogglePassword(false)}
                  />
                ) : (
                  <Visibility
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      setTogglePassword(true);
                    }}
                  />
                )}
              </div>
            </div>

            {<Error field="password" />}
          </div>

          {/* <div className="form-check text-center mtb-16">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              checked={rememberMe}
               onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Remember me
            </label>
          </div> */}

          <button
            type="submit"
            className="btn form-button mt-3"
            onClick={e => handleLogin(e)}
          >
            Login
          </button>
          <div
            className="text-center link-label"
            onClick={() => navigate("/forgotPassword")}
          >
            Forgot Password ?
          </div>

          <div
            className="text-center link-label"
            onClick={() => navigate("/register")}
          >
            Create Account
          </div>
        </form>
      </div>
      {loading && <Loader />}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.accessToken
  };
};

export default compose(
  enhancer,
  connect(mapStateToProps, {
    login,
    success,
    error,
    toggleOneTimeModal,
    toggleSubscriptionLoader,
    fetching,
    setUser
  })
)(Login);
