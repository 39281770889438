import React, { Fragment } from "react";
import LoaderImage from "../../assets/images/Frame.gif";

import { FallingLines } from "react-loader-spinner";
const LoaderComponent = (props) => {
  return (
    // <>
    // <div style={{position:"relative", minHeight: "100vh"}}>
    // <div className='loader-child-class' style={{}}>
    // <FallingLines
    //      color="#4fa94d"
    //      width="100"
    //      visible={true}
    //      ariaLabel="falling-circles-loading"
    // />
    // </div>
    // </div>
    // </>

    <>
      <div style={{ position: "relative", minHeight: "100vh" }}>
        <div className="loader-child-class">
          {/* <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span> */}
          <img
            src={LoaderImage}
            style={{ width: "100px", height: "100px", objectFit: "contain" }}
          />
        </div>
      </div>
    </>
  );
};

export default LoaderComponent;
