import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    body: Yup.string().required("Body is required"),
    title: Yup.string().required("Title is required"),
    sections: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Section Title is required"),
      })
    ),
  }),
  mapPropsToValues: (props) => ({
    body: "",
    title: "",
    sections: [],
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
