import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    body: Yup.string().required("Body is required"),
    catalogueId: Yup.string().required("Catalogue Id is required"),
    retailerId: Yup.object().shape({
      id: Yup.string().required("Id is Required"),
      mediaUrl: Yup.string().required("Media is Required"),
      name: Yup.string().required("Name is Required"),
      description: Yup.string().required("Description is Required"),
      retailerId: Yup.string().required("RetialerId is Required"),
      price: Yup.string().required("Price is Required"),
    }),
  }),
  mapPropsToValues: (props) => ({
    body: "",
    catalogueId: "",
    retailerId: {
      id: "",
      mediaUrl: "",
      name: "",
      description: "",
      retailerId: "",
      price: "",
    },
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
