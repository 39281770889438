import {
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ModalBody } from "reactstrap";
import subscriptionAction from "redux/subscription/action";
import {
  getAttributDropDownData,
  submitNewTagData,
} from "services/flowServices";
import { Handle, useStore, Position } from "reactflow";

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const { success, error, fetching } = subscriptionAction;

const handleStyle = { left: 10 };

function QuestionNode({
  props,
  setSidebarState,
  setSelectedNode,
  onImageUpload,
  companyId,
  onTextChange,
  setIdForTemplate,
  setIsChildLoading,
  duplicateNode,
  setErrorsArray,
  isChildLoading,
  deleteParentNode,
}) {
  const [parentId, setParentId] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOptions, setDropDownOptions] = useState([]);
  const mapData = ["Question", "Attribute", "Format", "Attempt", "Validation"];
  const formatOptions = [
    { id: 1, label: "Any" },
    { id: 2, label: "Text" },
    { id: 3, label: "Number" },
    { id: 4, label: "Yes/No" },
    { id: 5, label: "Email" },
    { id: 7, label: "Date" },
  ];
  const [valuesObj, setValuesObj] = useState({
    Question: "",
    Attribute: {},
    Format: "",
    Attempt: 0,
    Validation: "",
    ExtraOptions: {},
  });

  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  useEffect(() => {
    if (valuesObj?.Question == "") {
      setErrorsArray((arr) => {
        const index = arr.findIndex((x) => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      setErrorsArray((arr) => {
        const filteredArr = arr.filter((x) => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [valuesObj]);

  const handleGetDropdownData = async () => {
    try {
      const res = await getAttributDropDownData(companyId);
      if (res.status == "success") {
        // console.log(res, "res from dropdown api");

        if (res?.data?.length > 0) {
          //   let masterArray = [];
          //   res?.data?.map((x) => {
          //     let temp = {
          //       value: x?.id,
          //       label: x?.name,
          //     };
          //     masterArray.push(temp);
          //   });

          setDropDownOptions(res.data);
        } else {
          let temp = [
            {
              value: null,
              label: "Nothing to select",
            },
          ];
          setDropDownOptions(temp);
        }
        // setDropDownOptions(res.data);
      } else {
        // console.log(res, "So    mething went wrong");
      }
    } catch (error) {
      console.log(error, "error while trying to get data for dropdown");
    }
  };

  useEffect(() => {
    if (companyId !== null && companyId !== undefined && companyId !== "") {
      handleGetDropdownData();
    }
  }, [companyId]);

  useEffect(() => {
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      // let temp = {
      //   id: props?.data?.value?.id,
      //   type: props?.data?.value?.type,
      // };

      setValuesObj(props?.data?.value);

      // setSelectedOption(temp);
      // setValuesObj({
      //   id: props?.data?.value?.id,
      //   value: props?.data?.value?.value,
      // });
    }
  }, []);

  const Error = ({ field }) => {
    return (
      <>
        {field == "Question" ? (
          <>
            {valuesObj?.Question == "" ? (
              <aside className="error-message-row">
                <span className={"error-msg"}>Question is required</span>
              </aside>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  const handleChange = (field, value, flag) => {
    if (flag == "1") {
      if (field == "format" && value !== "Number") {
        let temp = {
          ...valuesObj,
          [field]: value,
          ExtraOptions: {},
        };
        onTextChange(props?.id, temp);
        setValuesObj(temp);
      } else {
        let temp = {
          ...valuesObj,
          [field]: value,
        };
        onTextChange(props?.id, temp);
        setValuesObj(temp);
      }
    } else if (flag == "2") {
      // console.log(field, "field?.target?.value");

      let temp = {
        ...valuesObj,
        ExtraOptions: {
          ...valuesObj.ExtraOptions,
          [field]: value,
        },
      };
      onTextChange(props?.id, temp);
      setValuesObj(temp);
    }
  };
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  return (
    <>
      <>
        <div className="text-updater-node flow-comn-box" onClick={() => {}}>
          <div className="flow-title-box">
            <aside>Question</aside>
            {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
            <>
              <div className="header-btn-row" style={{ paddingRight: "15px" }}>
                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i class="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i class="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
              <Handle
                id={`${props?.id}_attribute`}
                type="source"
                className="flow-trigger"
              ></Handle>
            </>
          </div>
          <div
            className="position-relative"
            onClick={() => {
              //   setSelectedNode(extractParentId(childNodeId));
              // setSidebarState(true)
            }}
            //  className="text-updater-node"
          >
            {/* <div className="flow-comn-close-btn" style={{ cursor: "pointer" }}>
              <i class="fas fa-times-circle"></i>
            </div> */}

            <div className="flow-inner-row">
              <div className="flow-inner-box pb-4">
                {mapData.map((field, index) =>
                  field === "Question" || field === "Validation" ? (
                    <>
                      <div style={{ position: "relative" }} className="mb-2">
                        {field}
                        <TextField
                          key={index}
                          fullWidth
                          multiline
                          minRows={field == "Question" ? 1.5 : 1}
                          placeholder={`Enter ${field} here`}
                          value={valuesObj[field]}
                          onChange={(e) =>
                            handleChange(field, e.target.value, "1")
                          }
                          size="medium"
                        ></TextField>
                        {field == "Question" ? <Error field={field} /> : null}
                      </div>
                    </>
                  ) : field == "Attribute" ? (
                    <>
                      <div className="mb-2">
                        {field}
                        <Select
                          className="nodrag"
                          style={{ width: "200px" }}
                          labelId={`demo-simple-select-label-${parentId}`}
                          id="demo-simple-select"
                          value={parseInt(valuesObj?.Attribute?.id)}
                          label="Age"
                          defaultValue={valuesObj?.Attribute?.id}
                        >
                          {dropdownOptions.map((x) => {
                            return (
                              <MenuItem
                                onClick={(e) => {
                                  handleChange(field, x, "1");
                                }}
                                value={x?.id}
                              >
                                {x?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </>
                  ) : field == "Format" ? (
                    <>
                      <div className="mb-2">
                        {field}
                        <Select
                          className="nodrag"
                          style={{ width: "200px" }}
                          labelId={`demo-simple-select-label-${parentId}`}
                          id="demo-simple-select"
                          value={parseInt(valuesObj?.Format?.id)}
                          label="Age"
                          defaultValue={valuesObj?.Format?.id}
                        >
                          {formatOptions.map((x) => {
                            return (
                              <MenuItem
                                onClick={(e) => {
                                  handleChange(field, x, "1");
                                }}
                                value={x?.id}
                              >
                                {x?.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="mb-2">
                        {valuesObj?.Format?.label == "Number" ? (
                          <>
                            <div className="minmax-row">
                              <input
                                key={index}
                                fullWidth
                                placeholder={`Min`}
                                value={valuesObj?.ExtraOptions?.min}
                                onChange={(e) =>
                                  handleChange("min", e.target.value, "2")
                                }
                                size="small"
                              />
                              <input
                                key={index}
                                fullWidth
                                placeholder={`Max`}
                                value={valuesObj?.ExtraOptions?.max}
                                onChange={(e) =>
                                  handleChange("max", e.target.value, "2")
                                }
                                size="small"
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : field == "Attempt" ? (
                    <>
                      <div className="mb-2">
                        {field}
                        <input
                          className="comn-form-control"
                          placeholder="Enter number of attempts"
                          type="number"
                          value={valuesObj?.attempt}
                          onChange={(e) =>
                            handleChange(field, e.target.value, "1")
                          }
                        />
                      </div>
                    </>
                  ) : null
                )}
              </div>
            </div>
          </div>
          {isConnecting ? (
            <>
              <Handle
                type="target"
                position={Position.Left}
                className="flow-trigger "
                style={{
                  overflow: "hidden",
                  width: "100%", // Adjust the width as needed
                  height: "100%",
                  // height: "110%",
                  background: isConnecting ? "transparent" : "transparent",
                  border: "none",
                  position: "absolute",
                  left: "0px", // Adjust position to the left side of the node
                  top: "50%",
                  zIndex: 9,
                  borderRadius: 0,
                  cursor: isConnecting ? "default" : "move", // Change cursor based on connection state
                }}
                isConnectable={isConnecting} // Disable connection when already connecting
              />
            </>
          ) : (
            <>
              <Handle
                type="target"
                position={Position.Left}
                className="flow-trigger flow-trigger-fill"
                style={{
                  left: "1px",
                  right: "auto",
                }}
                isConnectable={isConnecting} // Disable connection when already connecting
              />
            </>
          )}
        </div>
      </>
    </>
  );
}

export default QuestionNode;
