import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { compose } from "redux";
import servicesAction from "../../../redux/services/action";
import { Delete, Edit, Edit2, Edit3, Trash, Eye } from "react-feather";
import { usePagination, useSortBy, useTable } from "react-table";
import {
  deActivateService,
  deleteServices,
  getServices
} from "services/taxiServices";
import { Switch } from "@mui/material";
// import ConfirmChargerModal from "../../../components/modal/ConfirmationModal";
import AddEditTaxiService from "../../../components/modal/AddEditServices";
import ConfirmLocationModal from "../../../components/modal/ConfirmationModal";
import { Modal } from "reactstrap";
import Pagination from "components/common/Pagination";
import AddIcon from "@mui/icons-material/Add";
import ReactTableWrapper from "components/reacttable/reacttbl.style";
import Loader from "components/Loader";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";

// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BACKEND_URI = process.env.REACT_APP_BACKEND_URI_UPLOAD;
const { success, error, fetching } = servicesAction;
const TaxiServices = props => {
  const { accessToken, error, success, fetching } = props;
  const [tbldata, settblData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, settotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [openDeleteModal, toggleDeleteModalOpen] = useState();
  const [deleteId, setDeleteID] = useState("");
  const [statusId, setStatusId] = useState("");
  const [updateStatusFeild, setupdateStatusFeild] = useState("");
  const [openSatusModal, toggleStatusModal] = useState();

  // const history = useHistory();
  const navigate = useNavigate();

  const editCharger = id => {
    navigate(`/editCharger/${id}`);
  };
  // const viewCharger = id => {
  //   navigate(`/viewCharger/${id}`);
  // };
  const addPage = () => {
    navigate("/addCharger");
  };
  const HeaderComponent = ({ title, renderSortArrow }) => {
    return (
      <div>
        {title}
        {renderSortArrow && renderSortArrow()}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              title="Name"
              renderSortArrow={() => renderSortArrow(tableInstance.column.id)}
            />
          );
        },
        accessor: "name"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              title="Description"
              renderSortArrow={() => renderSortArrow(tableInstance.column.id)}
            />
          );
        },
        accessor: "description"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title="Logo" />;
        },
        accessor: "file_image"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title="Status" />;
        },
        accessor: "status",
        Cell: (row, i) => {
          return (
            <div>
              <Switch
                checked={row.cell.value ? true : false}
                onClick={() => {
                  setStatusId(row.cell.row.original._id);
                  setupdateStatusFeild(row.cell.value);
                  toggleStatusModal(true);
                }}
                color="primary"
                style={{ color: "blue" }}
              />
            </div>
          );
        }
      },

      {
        Header: tableInstance => {
          return <HeaderComponent title="Action" />;
        },
        accessor: "actions",
        Cell: (row, i) => {
          return (
            <div className=" d-flex ">
              <div>
                <Edit3
                  size={18}
                  onClick={() => {
                    setIsEdit(true);
                    setModal(true);
                    setEditData(row?.row.original);
                  }}
                  className="action-edit "
                />
              </div>
              <div className="ml-3">
                <Trash
                  className="action-delete"
                  background-color="#ffe5e5"
                  size={20}
                  color="red"
                  onClick={() => {
                    toggleDeleteModalOpen(true);
                    setDeleteID(row.row.original?._id);
                  }}
                />
              </div>
            </div>
          );
        }
      }
    ],
    [refresh]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    state: { pageIndex },
    headerGroups
  } = useTable(
    {
      data: tbldata,
      columns: columns,
      manualSortBy: true,
      initialState: {
        pageSize: limit,
        pageIndex: 0
      }
    },
    useSortBy,
    usePagination
  );

  const callApi = useCallback(async () => {
    setLoading(true);
    const data = {
      currentPage: currentPage + 1,
      limit: limit,
      search: search,
      sortBy: sortBy,
      sortOrder: sortOrder
    };
    await getServices(accessToken, data).then(r => {
      if (r.success) {
        settblData(r.data.docs);
        settotalPage(r.data.totalPages);
        setRefresh(false);
        setLoading(false);
      } else {
        error(r.message);
      }
    });
  });

  useEffect(() => {
    refresh && callApi();
  }, [refresh, currentPage]);

  useEffect(() => {
    if (search !== undefined) {
      const timeOutId = setTimeout(() => setCurrentPage(0), callApi(), 100);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const handleSort = field => {
    if (field === "id") {
      return;
    }
    if (sortBy === field) {
      // setPageCng(1);
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      setRefresh(true);
    } else {
      // setPageCng(1);
      setSortBy(field);
      setSortOrder("asc");
      setRefresh(true);
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    setRefresh(true);
  };

  const handleChange = async () => {
    let value = updateStatusFeild;
    const data = {
      status: value ? false : true
    };
    fetching();
    const res = await deActivateService(accessToken, statusId, data);
    if (res.success) {
      toggleStatusModal(false);
      success("Success");
      setRefresh(true);
    }
  };

  const handeldelete = async id => {
    fetching();
    const res = await deleteServices(accessToken, deleteId);
    if (res.success) {
      toggleDeleteModalOpen(false);
      success(res.message);
      setRefresh(true);
    }
  };

  const renderSortArrow = column => {
    if (column !== sortBy) {
      return null; // no arrow for non-sorting columns
    }
    if (sortOrder !== "asc") {
      return <i className="fa fa-sort-down ml-1 text-dark"></i>; // ascending arrow
    } else {
      return <i className="fa fa-sort-up ml-1 text-dark"></i>; // descending arrow
    }
  };

  return (
    <>
      <div className="row ">
        <div className=" col-12 d-flex justify-content-end">
          <div className="mt-3">
            <input
              className="form-control react-form-input "
              type="search"
              placeholder="Search Taxi Service"
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
          </div>

          <div className="mt-3 ml-3">
            <button
              type="submit"
              className=" btn btn-primary w-10 addbtncolor"
              onClick={() => {
                setIsEdit(false);
                setModal(true);
              }}
            >
              <AddIcon className="mr-2" />
              Add Service
            </button>
          </div>
        </div>

        <div className="col-12 mt-3">
          {
            <ReactTableWrapper {...props}>
              <div className="table-responsive table-white-box">
                <div
                  style={{
                    maxHeight: `calc(100vh - 250px)`,
                    position: "relative"
                  }}
                >
                  <table className="table" {...getTableProps()}>
                    <thead
                      style={{ position: "sticky", top: "-20px", zIndex: 1 }}
                    >
                      {headerGroups.map(headerGroup => (
                        <tr
                          className="thead-color text-center"
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(header => (
                            <th
                              {...header.getHeaderProps(
                                header.getSortByToggleProps()
                              )}
                              onClick={() => handleSort(header.id)}
                            >
                              <div>{header.render("Header")}</div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    {tbldata.length ? (
                      <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map(cell => (
                                <td
                                  className="text-center"
                                  {...cell.getCellProps()}
                                >
                                  {cell.column.id === "file_image" ? (
                                    <img
                                      src={`${BACKEND_URI}/${cell.value}`}
                                      alt="Image"
                                      className="imageView"
                                    />
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : !refresh && !tbldata.length ? (
                      <tr>
                        <td
                          className="mt-4 text-center font-weight-bold"
                          colSpan={6}
                        >
                          <h5>No data found</h5>
                        </td>
                      </tr>
                    ) : (
                      <Loader />
                    )}
                  </table>
                </div>
              </div>
              <Pagination
                onPageChange={handlePageChange}
                page={currentPage}
                pages={totalPage}
              />
            </ReactTableWrapper>
          }
          <Modal isOpen={modal} backdrop={true}>
            {modal && (
              <AddEditTaxiService
                isEdit={isEdit}
                editData={editData}
                onClose={() => {
                  setModal(false);
                  setIsEdit(false);
                  setEditData({});
                }}
                toggleRefresh={e => setRefresh(e)}
              />
            )}
          </Modal>

          <Modal isOpen={openSatusModal} backdrop={true}>
            {openSatusModal && (
              <ConfirmLocationModal
                isOpen={openSatusModal}
                onClose={() => toggleStatusModal(false)}
                confirmText={"Update"}
                cancelBtnBsStyle=" modalcancelbutton btn-secondary"
                message={"Are you sure you want to Update Status?"}
                handleConfirm={() => handleChange()}
              />
            )}
          </Modal>

          <Modal isOpen={openDeleteModal} backdrop={true}>
            {openDeleteModal && (
              <ConfirmLocationModal
                isOpen={openDeleteModal}
                onClose={() => toggleDeleteModalOpen(false)}
                confirmText={"Delete"}
                cancelBtnBsStyle=" modalcancelbutton btn btn-secondary"
                message={"Are you sure you want to delete?"}
                handleConfirm={() => handeldelete()}
              />
            )}
          </Modal>
          {loading && <Loader />}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  };
};

export default compose(connect(mapStateToProps, { success, error, fetching }))(
  TaxiServices
);
