import {
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getAppointmentFormData,
  getTemplateOrderData,
} from "services/flowServices";
import Enhancer from "../Enhancers/AttributeEnhancer";
import { ErrorMessage } from "formik";
import { Handle, Position, useStore } from "reactflow";
const connectionNodeIdSelector = (state) => state.connectionNodeId;

const AppointmentNode = ({
  props,
  deleteParentNode,
  onTextChange,
  companyId,
  setValues,
  errors,
  values,
}) => {
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;
  const [allValues, setAllValues] = useState({
    body: "",
    buttonTitle: "",
    appointmentForm: "",
    paymentBoolean: false,
    paymentAmount: 0,
    paymentCurrency: "",
    paymentTemplateId: "",
  });
  const [appointmentDropdownOptions, setAppointmentDropdownOptions] = useState(
    []
  );

  const [
    orderTemplateDropdownOptions,
    setOrderTemplateDropdownOptions,
  ] = useState([]);
  const currencyDropdownOptions = [
    { name: "INR", value: "INR" },
    { name: "SGD", value: "SGD" },
    { name: "BRL", value: "BRL" },
    { name: "USD", value: "USD" },
  ];

  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  const getAppointmentData = async () => {
    try {
      if (companyId) {
        const res = await getAppointmentFormData(companyId);

        if (res?.status === "success") {
          if (res?.data?.length > 0) {
            const tempArray = res.data.map((option) => ({
              name: option?.name,
              value: option?.flow_id,
            }));
            setAppointmentDropdownOptions(tempArray);
          } else {
            // If no options are available
            setAppointmentDropdownOptions([
              {
                name: "Nothing To Select",
                value: "ew123",
                disabled: true, // Mark as disabled
              },
            ]);
          }
        }
      }
    } catch (error) {
      console.log(error, "Error while trying to getAppointmentFormData");
    }
  };

  const getOrderTemplateData = async () => {
    try {
      if (companyId) {
        const res = await getTemplateOrderData(companyId);

        if (res?.status === "success") {
          if (res?.data?.length > 0) {
            const tempArray = res.data.map((option) => ({
              name: option?.name,
              value: option?.id,
            }));
            setOrderTemplateDropdownOptions(tempArray);
          } else {
            // If no options are available
            setOrderTemplateDropdownOptions([
              {
                name: "Nothing To Select",
                value: "ew123",
                disabled: true, // Mark as disabled
              },
            ]);
          }
        }
      }
    } catch (error) {
      console.log(error, "Error while trying to getOrderTemplateData");
    }
  };

  useEffect(() => {
    getAppointmentData();
    getOrderTemplateData();
  }, [companyId]);

  useEffect(() => {
    setValues(allValues);
    onTextChange(props?.id, allValues);
  }, [allValues]);

  useEffect(() => {
    if (props?.data?.value) {
      setAllValues(props?.data?.value);
      onTextChange(props?.id, props?.data?.value);
      setValues(props?.data?.value);
    }
  }, []);

  const handleCommonValueChange = (fieldName, value) => {
    try {
      const temp = { ...allValues };
      let finalValue =
        fieldName === "paymentBoolean" ? !temp[fieldName] : value;
      temp[fieldName] = finalValue;

      setAllValues(temp);
    } catch (error) {
      console.log(error, "Error while trying to handleCommonValueChange");
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "var(--purple-color)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
    <div className="text-updater-node flow-comn-box">
      <div className="flow-title-box">
        <aside>Message</aside>

        <div className="header-btn-row" style={{ paddingRight: "15px" }}>
          {/* <Tooltip title="Clone">
            <div
              onClick={() => {
                duplicateNode(props?.id);
              }}
              className="preview-clone-button"
            >
              <i class="fas fa-clone"></i>{" "}
            </div>
          </Tooltip> */}
          <Tooltip title="Delete">
            <div
              onClick={handleDeleteParentNode}
              className="preview-clone-button"
            >
              <i class="fas fa-trash"></i>{" "}
            </div>
          </Tooltip>
        </div>
        <Handle
          id={`${props?.id}_Appointment`}
          type="source"
          className="flow-trigger"
        ></Handle>
      </div>
      <div>
        <div className="flow-inner-row">
          <div className="flow-inner-box pb-4">
            <div className="my-2">
              <TextField
                size="large"
                className="w-100 textfield-padding-small"
                id={`appointmentForm-${props?.id}-body`}
                // type="number"
                minRows={1}
                placeholder="Enter Body Here"
                value={allValues["body"]}
                onChange={(e) =>
                  handleCommonValueChange("body", e.target.value)
                }
                label="Body"
              />
            </div>
            <div className="my-2">
              <TextField
                size="large"
                className="w-100 textfield-padding-small"
                id={`appointmentForm-${props?.id}-buttonTitle`}
                minRows={1}
                placeholder="Button Title"
                value={allValues["buttonTitle"]}
                onChange={(e) =>
                  handleCommonValueChange("buttonTitle", e.target.value)
                }
                label="Button Title"
              />
            </div>

            <span style={{ display: "block", marginBottom: "10px" }}>
              Select for appointment
            </span>
            <Select
              className="nodrag dropdown-zindex"
              style={{ width: "200px" }}
              labelId={`demo-simple-select-label-${props?.id}`}
              id="demo-simple-select"
              value={allValues?.appointmentForm}
              onChange={(e) =>
                handleCommonValueChange("appointmentForm", e.target.value)
              }
            >
              {appointmentDropdownOptions &&
                appointmentDropdownOptions.length > 0 &&
                appointmentDropdownOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.value}
                    disabled={option.disabled} // Apply disabled state
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
            <ErrorMessage name="appointmentForm" component="div" />

            {allValues?.appointmentForm !== "" && (
              <div>
                <div style={{ marginBlock: "10px" }}>
                  <span className="d-inline-block mr-2 pr-1">
                    Payment Required
                  </span>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    // label="iOS style"
                    checked={allValues?.paymentBoolean}
                    onChange={(e) =>
                      handleCommonValueChange(
                        "paymentBoolean",
                        e.target.checked
                      )
                    }
                  />
                </div>

                {allValues?.paymentBoolean && (
                  <>
                    <div>
                      <div className="my-2">
                        <TextField
                          size="large"
                          className="w-100 textfield-padding-small"
                          id={`appointmentForm-${props?.id}-amount`}
                          type="number"
                          minRows={1}
                          placeholder="Enter Text Here"
                          value={allValues["paymentAmount"]}
                          onChange={(e) =>
                            handleCommonValueChange(
                              "paymentAmount",
                              e.target.value
                            )
                          }
                          label="Payment Amount"
                        />
                      </div>

                      <div className="my-2">
                        Select for currency
                        <Select
                          className="nodrag dropdown-zindex"
                          style={{ width: "200px" }}
                          labelId={`demo-simple-select-label-${props?.id}`}
                          id="demo-simple-select"
                          value={allValues?.paymentCurrency}
                          onChange={(e) =>
                            handleCommonValueChange(
                              "paymentCurrency",
                              e.target.value
                            )
                          }
                        >
                          {currencyDropdownOptions?.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div>
                        Select for template
                        <Select
                          className="nodrag dropdown-zindex"
                          style={{ width: "200px" }}
                          labelId={`demo-simple-select-label-${props?.id}`}
                          id="demo-simple-select"
                          value={allValues?.paymentTemplateId}
                          onChange={(e) =>
                            handleCommonValueChange(
                              "paymentTemplateId",
                              e.target.value
                            )
                          }
                        >
                          {orderTemplateDropdownOptions &&
                            orderTemplateDropdownOptions.length > 0 &&
                            orderTemplateDropdownOptions?.map(
                              (option, index) => (
                                <MenuItem
                                  key={index}
                                  value={option.value}
                                  disabled={option.disabled} // Apply disabled state
                                >
                                  {option.name}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {isConnecting ? (
        <>
          <Handle
            type="target"
            position={Position.Left}
            className="flow-trigger "
            style={{
              overflow: "hidden",
              width: "100%", // Adjust the width as needed
              height: "100%",
              // height: "110%",
              background: isConnecting ? "transparent" : "transparent",
              border: "none",
              position: "absolute",
              left: "0px", // Adjust position to the left side of the node
              top: "50%",
              zIndex: 9,
              borderRadius: 0,
              cursor: isConnecting ? "default" : "move", // Change cursor based on connection state
            }}
            isConnectable={isConnecting} // Disable connection when already connecting
          />
        </>
      ) : (
        <>
          <Handle
            type="target"
            position={Position.Left}
            className="flow-trigger flow-trigger-fill"
            style={{
              left: "1px",
              right: "auto",
            }}
            isConnectable={isConnecting} // Disable connection when already connecting
          />
        </>
      )}
    </div>
  );
};

export default Enhancer(AppointmentNode);
