import { Input, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";


function CustomTextNode({
  props,
  text,
  setSelectedNode,
  setSidebarState,
  onTextChange,
}) {
  // const{id,data} = props
  // const {data} = props

  const [childNodeId, setChildNodeId] = useState("");
  const [details, setDetails] = useState();
  const [textBoxHeight, setTextBoxHeight] = useState("46");
  const [textValue,setTextValue] = useState("")

  useEffect(() => {
    if (props?.id) {
      setChildNodeId(props?.id);
    }

    if(props?.data){
      setTextValue(props?.data?.value)
    }
  });

  const onChange = (evt) => {
    const newText = evt.target.value;
    setDetails((prevDetails) => ({ ...prevDetails, name: newText }));
    // console.log(data,"Data. id")
    const newHeight = evt.target.scrollHeight;

    //let heightDifference
    // if(newHeight<textBoxHeight){
    //   heightDifference = newHeight - textBoxHeight
    // }
    // else if(newHeight > textBoxHeight){
    //   heightDifference = newHeight - textBoxHeight
    // }else{
    //   heightDifference = 0
    // }
    const heightDifference = newHeight - textBoxHeight;
    console.log(
      newHeight,
      "newHeight",
      heightDifference,
      "heightDifference",
      textBoxHeight,
      "textBoxHeight"
    );
    setTextBoxHeight(newHeight);
    setTextValue(newText)
    // console.log("after height update");
    onTextChange(props.id, newText, heightDifference);
  };
  useEffect(() => {
    console.log({ textBoxHeight });
  }, [textBoxHeight]);

  function extractParentId(childId) {
    // Split the childId by "-"
    const parts = childId.split("-");

    // If there is more than one part after splitting, it means it follows the pattern
    if (parts.length > 1) {
      // The first part is the parent id
      return parts[0];
    } else {
      // If there is only one part, it might be the parent id itself
      return childId;
    }
  }
  useEffect(() => {
    // Set draggable attribute to false using JavaScript
    const noDragElements = document.querySelectorAll('.no-drag');
    noDragElements.forEach((element) => {
      element.draggable = false;
    });

    // Optionally, add an event listener to prevent text selection
    const preventTextSelection = (event) => {
      event.preventDefault();
    };

    noDragElements.forEach((element) => {
      element.addEventListener('selectstart', preventTextSelection);
    });

    // Clean up event listeners on component unmount
    return () => {
      noDragElements.forEach((element) => {
        element.removeEventListener('selectstart', preventTextSelection);
      });
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  return (
    <div
      className="nodrag position-relative"
      onClick={() => {
        setSelectedNode(extractParentId(childNodeId));
        // setSidebarState(true)
      }}
      //  className="text-updater-node"
    >
      {/* <span style={{ textAlign: "center" }}>{details?.name}</span> */}

      {/* <div style={{display:"flex", justifyContent:"center"}}>
                <label htmlFor="text">Child Node : {childNodeId}</label>
                <Input onChange={onChange} type='text' />

            </div> */}

      <div className="flow-inner-row nodrag">
        <div className="flow-inner-box nodrag">
          <TextField
          className="nodrag"
            size="large"
            multiline
            // variant="standard"
            value={textValue}
            placeholder="Enter your text here"
            minRows={2}
            onChange={(e) => {
              onChange(e);
            }}
          />
          {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deleniti aperiam et esse cum fugit sit, porro, nam eligendi molestiae quae ad eveniet! A reiciendis, laudantium velit sint incidunt tempore accusantium.</p> */}
        </div>
      </div>
      {/* <Handle draggable='false' style={{...HandleStyleCheck.top}}  isConnectableStart='true' type="source" > */}
      {/* <i class="fas fa-circle"></i> */}
      {/* </Handle> */}
    </div>
  );
}

export default CustomTextNode;
