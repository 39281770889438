import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    file_image: Yup.mixed().required("Please select logo"),
    name: Yup.string().required("Please enter name"),
    description: Yup.string().required("Please enter the description")
  }),
  mapPropsToValues: props => ({
    file_image: "",
    name: "",
    description: ""
  }),
  validateOnMount: true,
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
