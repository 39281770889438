import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Handle, useStore, Position } from "reactflow";
import Enhancer from "../Enhancers/MultiProductEnhancer";
import subscriptionAction from "redux/subscription/action";
import { getCatalogueData, getRetailerData } from "services/flowServices";
import { X } from "react-feather";

const connectionNodeIdSelector = (state) => state.connectionNodeId;
const TestingMultiProductNode = ({
  props,
  setSidebarState,
  setSelectedNode,
  deleteParentNode,
  onTextChange,
  duplicateNode,
  setPreviewState,
  setValues,
  errors,
  setErrorsArray,
  touched,
  values,
  companyId,
  submitCount,
  handleBlur,
}) => {
  const { success, error, fetching } = subscriptionAction;

  const [parentId, setParentId] = useState();
  const [allValues, setAllValues] = useState({
    header: "",
    body: "",
    footer: "",
    catalogueId: "",
    sections: [],
  });
  const [catalogueDropdownOptions, setCatalogueDropdownOptions] = useState([]);
  const [retaileDropdownOptions, setRetailDropdownOptions] = useState([]);
  const [
    retailerDropdownIndividualOptions,
    setRetailerDropdownIndividualOptions,
  ] = useState([]);

  function extractParentId(childId) {
    const parts = childId.split("-");
    if (parts.length > 1) {
      return parts[0];
    } else {
      return childId;
    }
  }

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  useEffect(() => {
    if (companyId) {
      handleGetCatalogueData(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (props?.id) {
      setParentId(extractParentId(props?.id));
    }
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      setAllValues(props?.data?.value);
      setValues(props?.data?.value);
    } else {
      setValues(allValues);
    }
  }, []);

  useEffect(() => {
    // console.log(errors, "if any from formik");
    // console.log(touched, "touched if any from formik  ");
    if (Object.keys(errors).length !== 0) {
      // console.log(errors, "errors array if");
      setErrorsArray((arr) => {
        const index = arr.findIndex((x) => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      // console.log(errors, "errors array else");
      setErrorsArray((arr) => {
        const filteredArr = arr.filter((x) => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [errors]);
  const addTextField = () => {
    const newFields = Array.from({ length: 1 }, (_, i) => ({
      id: `textField${allValues.sections.length + i + 1}`,
      title: "",
      retailerId: [
        {
          id: "",
          description: "",
          mediaUrl: "",
          name: "",
          price: "",
          retailerId: "",
        },
      ],
    }));

    const id = newFields[0].id;

    const retailerOptions = [...retaileDropdownOptions];

    const obj = { id, options: retailerOptions };

    setRetailerDropdownIndividualOptions((prevOptions) => [
      ...prevOptions,
      obj,
    ]);
    setAllValues((prevState) => {
      let temp = {
        ...prevState,
        sections: [...prevState.sections, ...newFields],
      };

      setValues(temp);
      return temp;
    });
  };

  const handleChange = (id, field, value, flag) => {
    if (flag === true) {
      let maxLength;

      if (id == "body") {
        maxLength = 1024;
      } else if (id == "header" || id == "footer") {
        maxLength = 60;
      }

      let newVal = value;
      if (newVal?.length > maxLength) {
        error(`Only ${maxLength} characters are allowed in ${id}`);
        newVal = newVal.slice(0, maxLength);
      }

      const temp = {
        ...allValues,
        [id]: newVal,
      };
      onTextChange(props?.id, temp);
      setValues(temp);
      setAllValues(temp);
    } else {
      const updatedSections = allValues.sections.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      );
      // setAllValues((prevState) => ({
      //   ...prevState,
      //   sections: updatedSections,
      // }));
      const temp = { ...allValues, sections: updatedSections };
      setValues(temp);
      setAllValues(temp);
      onTextChange(props?.id, temp);
    }
  };

  console.log({ retailerDropdownIndividualOptions });
  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  const setAdditionalFieldsData = (id, value, index, flag, ind) => {
    const tempData = allValues.sections.map((section, idx) => {
      if (idx === index) {
        if (flag === "title") {
          if (value.length > 24) {
            error("Maximum 24 characters are allowed in title");
            value = value.slice(0, 24);
          }
          return {
            ...section,
            title: value,
          };
        } else if (flag === "retailerId") {
          return {
            ...section,
            retailerId: section.retailerId.map((item, i) =>
              i === ind ? { ...item, value } : item
            ),
          };
        }
      }
      return section;
    });

    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: tempData,
    // }));
    const temp = {
      ...allValues,
      sections: tempData,
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  const Error = (props) => {
    const field1 = props.field;
    if (errors[field1] || touched[field1] || submitCount > 0) {
      // console.log("inside if any from formik");
      return (
        <>
          {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
          {/* </p> */}
          <aside className="error-message-row">
            <span className={props.class ? props.class : "error-msg"}>
              {errors[field1]}
            </span>
          </aside>
        </>
      );
    } else {
      return <span />;
    }
  };

  console.log(errors, "Errors here");
  // Modified Error component to handle errors for buttons
  const ButtonError = ({ index, flag, index2 }) => {
    // console.log("if any from formik errors:", errors);
    // console.log("if any from formik touched:", touched);

    if ((errors.sections && errors.sections[index]) || submitCount > 0) {
      // console.log(" if any from formik Error condition satisfied.");
      return (
        <aside className="error-message-row">
          <span className={props.class ? props.class : "error-msg"}>
            {flag === "title"
              ? errors.sections[index].title
              : Array.isArray(errors.sections[index].retailerId)
              ? errors.sections[index].retailerId[index2]?.id
              : null}
          </span>
        </aside>
      );
    } else {
      // console.log("if any from formik No error.");
      return <span />;
    }
  };

  useEffect(() => {
    setValues(allValues);
    onTextChange(props?.id, allValues);
  }, [allValues]);
  const addRetailerField = (fieldId) => {
    const updatedFields = allValues.sections.map((field) =>
      field.id === fieldId
        ? {
            ...field,
            retailerId: [
              ...field.retailerId,
              {
                id: "",
                mediaUrl: "",
                name: "",
                description: "",
                retailerId: "",
                price: "",
              },
            ],
          }
        : field
    );
    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: updatedFields,
    // }));
    const temp = {
      ...allValues,
      sections: updatedFields,
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  const removeRetailer = (pIndex, cIndex) => {
    const updatedData = allValues.sections.map((section, idx) => {
      if (idx === pIndex) {
        return {
          ...section,
          retailerId: section.retailerId.filter((item, i) => i !== cIndex),
        };
      }
      return section;
    });
    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: updatedData,
    // }));
    const temp = {
      ...allValues,
      sections: updatedData,
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  useEffect(() => {
    // console.log(errors, "if any multi product");
  }, [errors]);

  const removeAllRetailerForParent = (index) => {
    const updatedData = allValues.sections.filter((_, idx) => idx !== index);
    // setAllValues((prevState) => ({
    //   ...prevState,
    //   sections: updatedData,
    // }));
    const temp = {
      ...allValues,
      sections: updatedData,
    };
    setValues(temp);
    setAllValues(temp);
    onTextChange(props?.id, temp);
  };

  const handleGetCatalogueData = async (companyId) => {
    try {
      const res = await getRetailerData(companyId);
      console.log(res, "Res here");
      if (res.status == "success") {
        console.log("success");

        let dropdownArray = [];
        if (res?.data?.length > 0) {
          let id = res?.data[0].catalogue_id;
          res?.data?.map((option, index) => {
            // let obj = {
            //   name: option.name,
            //   id: option.id,
            // };

            dropdownArray.push(option);
          });
        } else {
          let obj = {
            name: "Noting To Select",
            id: null,
          };

          dropdownArray.push(obj);
        }

        setRetailDropdownOptions(dropdownArray);
        if (res?.data?.length > 0 && res?.data[0].id) {
          const idToSet = res?.data[0].catalogue_id;
          // handleGetRetailerDetails(idToSet);
          const temp = { ...allValues };
          temp.catalogueId = idToSet;
          setAllValues((prevValues) => {
            prevValues.catalogueId = idToSet;
            console.log({ prevValues });
            return prevValues;
          });
        }
      }
    } catch (error) {
      console.log(error, "Error while trying to handleGetCatalogueData");
    }
  };

  const handleCatalogueSelect = (option) => {
    console.log(option, "options");
    let temp = { ...allValues };
    temp.catalogueId = option.target.value;
    temp.sections = [];
    // handleGetRetailerDetails(option.target.value);
    console.log(temp, "temp here");

    setAllValues(temp);
  };

  const handleGetRetailerDetails = async (catalogueId) => {
    try {
      const res = await getRetailerData(companyId, catalogueId);

      console.log(res, "Res here");
      if (res.status == "success") {
        setRetailDropdownOptions(res?.data);
      }
    } catch (error) {
      console.log(error, "Error while trying to handleGetRetailerDetails");
    }
  };

  const handleRetailSelect = (option, sectionIndex, retailIndex) => {
    try {
      const temp = { ...allValues };

      let id = option.target.value;

      const retailObject = retaileDropdownOptions.find((x) => x.id == id);

      let tempObj = {
        id: retailObject.id,
        mediaUrl: retailObject.image_url,
        name: retailObject.name,
        description: retailObject.description,
        retailerId: retailObject.retailer_id,
        price: retailObject.price,
      };

      temp.sections[sectionIndex].retailerId[retailIndex] = tempObj;

      console.log(temp, "temp here");
      setAllValues(temp);

      // setRetailerDropdownIndividualOptions((prevOptions) => {
      //   prevOptions.filter((item) => item.id !== id);
      //   console.log(prevOptions, "prevOptions");
      //   return [...prevOptions];
      // });
    } catch (error) {
      console.log(error, "Error while trying to handleRetailSelect");
    }
  };

  console.log(allValues, "all values here");
  return (
    <>
      <div className="text-updater-node flow-comn-box" onClick={() => {}}>
        <div className="flow-title-box">
          <aside>Message</aside>
          {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
          {props?.type !== "Template" ? (
            <>
              <div className="header-btn-row">
                <Tooltip title="Preview">
                  <div
                    onClick={() => {
                      setSelectedNode(props?.id);
                      setPreviewState(true);
                    }}
                    className="preview-eye-button"
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </Tooltip>

                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i class="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i class="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>

        <div className="flow-inner-row flow-textarea-row">
          {["header", "body", "footer"].map((field, index) => (
            <>
              <aside>
                <TextField
                  className="textfield-padding-small"
                  size="large"
                  multiline={true}
                  id={field}
                  onBlur={handleBlur}
                  minRows={field == "body" ? 4 : 1}
                  placeholder={
                    field == "body" ? `${field}` : `${field}(optional)`
                  }
                  value={allValues[field]}
                  onChange={(e) =>
                    handleChange(field, "value", e.target.value, true)
                  }
                />
                <Error field={field} />
                <div className="counter-class">
                  {allValues[field]?.length !== 0 && field !== "catalogueId"
                    ? `${allValues[field]?.length}/${
                        field == "body"
                          ? 1024
                          : field == "header" || field == "footer"
                          ? 60
                          : field == "title"
                          ? 24
                          : null
                      }`
                    : null}
                </div>
              </aside>
            </>
          ))}

          {/* <div
            style={{
              margin: "9px 0px 4px",
              padding: "3px",
              backgroundColor: "white",
            }}
          >
            Catalogue Id
          </div>
          <Select
            className="w-100 nodrag dropdown-zindex select-height"
            style={{ width: "200px" }}
            labelId={`demo-simple-select-label2-${parentId}`}
            id="demo-simple-select2"
            value={allValues?.catalogueId}
            label="Age"
            onChange={(e) => handleCatalogueSelect(e)}
          >
            {catalogueDropdownOptions && catalogueDropdownOptions.length > 0 ? (
              catalogueDropdownOptions.map((option) => {
                return <MenuItem value={option?.id}>{option?.name}</MenuItem>;
              })
            ) : (
              <MenuItem disabled>Nothing To Select</MenuItem>
            )}
          </Select> */}
          {allValues.sections.map((field, index) => (
            <div
              style={{ position: "relative " }}
              className="text-btn-add-row mt-3"
            >
              <p
                className="flow-comn-close-btn"
                style={{ cursor: "pointer" }}
                onClick={() => removeAllRetailerForParent(index)}
              >
                <i className="fas fa-times-circle"></i>
              </p>
              <div style={{ position: "relative" }}>
                <TextField
                  className="textfield-padding-small"
                  label={`Section Title ${index + 1}`}
                  value={field.title}
                  multiline={false}
                  id="sections"
                  onBlur={handleBlur}
                  minRows={1}
                  onChange={(e) =>
                    setAdditionalFieldsData(
                      field.id,
                      e.target.value,
                      index,
                      "title"
                    )
                  }
                />
                <ButtonError index={index} flag="title" />
                <div className="counter-class">
                  {field.title?.length !== 0
                    ? `${field.title?.length}/24`
                    : null}
                </div>
              </div>
              {field.retailerId &&
                field.retailerId.length > 0 &&
                field.retailerId.map((x, ind) => {
                  return (
                    <div
                      style={{
                        position: "relative",
                        borderTop: "1px solid #ccc",
                      }}
                      className=""
                    >
                      <React.Fragment key={ind}>
                        {/* <TextField
                            label={`Retailer Id ${ind + 1}`}
                            value={x.value}
                            multiline
                            minRows={2}
                            style={{ backgroundColor: "white", width: "100%" }}
                            onChange={(e) =>
                              setAdditionalFieldsData(
                                field.id,
                                e.target.value,
                                index,
                                "retailerId",
                                ind
                              )
                            }
                          /> */}
                        {console.log(allValues, "All values here")}
                        <aside style={{ bordeTop: "1px solid #ccc" }}>
                          {!allValues?.sections[index].retailerId[ind].id &&
                            allValues?.sections[index].retailerId[ind].id ==
                              "" && (
                              <>
                                <div
                                  style={{
                                    margin: "9px 0px 4px",
                                    // padding: "3px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  Product
                                </div>

                                {console.log(
                                  allValues?.sections[index].retailerId[ind],
                                  "ASdasdasdasdasdasdasd retailerDropdownIndividualOptions"
                                )}
                                <Select
                                  className="w-100 nodrag dropdown-zindex"
                                  labelId={`demo-simple-select-label-${parentId}`}
                                  id="demo-simple-select"
                                  value={
                                    allValues?.sections[index].retailerId[ind]
                                      .id
                                  }
                                  label="Age"
                                  // renderValue={(selected) => (
                                  //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                  //     {selected.map((value) => (
                                  //       <Chip key={value} label={value} />
                                  //     ))}
                                  //   </Box>
                                  // )}
                                  onChange={(e) =>
                                    handleRetailSelect(e, index, ind)
                                  }
                                >
                                  {retaileDropdownOptions &&
                                  retaileDropdownOptions.filter(
                                    (x) =>
                                      x.id !==
                                      allValues?.sections[index].retailerId[ind]
                                        .id
                                  ) &&
                                  retaileDropdownOptions.length > 0 ? (
                                    retaileDropdownOptions.map((option) => {
                                      return (
                                        // <>
                                        //   {option?.id !==
                                        //   allValues?.sections[index].retailerId[
                                        //     ind
                                        //   ].id ? (
                                        // <>
                                        //   {" "}
                                        <MenuItem value={option?.id}>
                                          {option?.name}
                                        </MenuItem>
                                        // </>
                                        //   ) : (
                                        //     <></>
                                        //   )}
                                        // </>
                                      );
                                    })
                                  ) : (
                                    <MenuItem disabled>
                                      Nothing To Select
                                    </MenuItem>
                                  )}
                                </Select>
                              </>
                            )}
                          <ButtonError
                            index={index}
                            flag="retailerId"
                            index2={ind}
                          />
                        </aside>

                        <p
                          className="flow-comn-close-btn"
                          onClick={() => removeRetailer(index, ind)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-times-circle"></i>
                        </p>
                        {allValues?.sections[index].retailerId[ind].id &&
                          allValues?.sections[index].retailerId[ind].id !==
                            "" && (
                            <>
                              <div className="common-card">
                                <span style={{ display: "block" }}>
                                  <b>
                                    {" "}
                                    {
                                      allValues?.sections[index].retailerId[ind]
                                        .name
                                    }
                                  </b>
                                  <img
                                    src={
                                      allValues?.sections[index].retailerId[ind]
                                        .mediaUrl
                                    }
                                  />
                                  <span>
                                    {
                                      allValues?.sections[index].retailerId[ind]
                                        .description
                                    }
                                  </span>
                                  <span
                                    style={{
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <b>
                                      {" "}
                                      {
                                        allValues?.sections[index].retailerId[
                                          ind
                                        ].price
                                      }
                                    </b>
                                  </span>
                                </span>
                              </div>
                            </>
                          )}
                      </React.Fragment>
                    </div>
                  );
                })}
              <Button
                className="flow-comn-btn"
                variant="contained"
                onClick={() => {
                  addRetailerField(field.id);
                }}
              >
                <i className="fas fa-plus mr-2"></i> Add Product
              </Button>
            </div>
          ))}
          <Button
            className="flow-comn-btn"
            variant="contained"
            onClick={addTextField}
          >
            <i className="fas fa-plus mr-2"></i> Add Section
          </Button>
          {/* Displaying all values of text fields */}
          {/* <pre>{JSON.stringify(allValues, null, 2)}</pre> */}
        </div>
        {isConnecting ? (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger "
              style={{
                overflow: "hidden",
                width: "100%", // Adjust the width as needed
                height: "100%",
                // height: "110%",
                background: isConnecting ? "transparent" : "transparent",
                border: "none",
                position: "absolute",
                left: "0px", // Adjust position to the left side of the node
                top: "50%",
                zIndex: 9,
                borderRadius: 0,
                cursor: isConnecting ? "default" : "move", // Change cursor based on connection state
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger flow-trigger-fill"
              style={{
                left: "1px",
                right: "auto",
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        )}
      </div>
    </>
  );
};

export default Enhancer(TestingMultiProductNode);
