import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    body: Yup.string().required("Body is required"),
    formId: Yup.object().required("Form Id is required"),
    buttonTitle: Yup.string().required("Button Title is required"),
    // retailerId: Yup.string().required("Retailer Id is required"),
  }),
  mapPropsToValues: (props) => ({
    body: "",
    // catalogueId: "",
    // retailerId: "",
    formId: "",
    buttonTitle: "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
