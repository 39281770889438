import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import 'reactflow/dist/style.css';
import { Button } from "@mui/material";
import Select from "react-select";
import { styles } from "react-select";
import ReactDOM from "react-dom";


import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ReactFlow, { Background, Controls, MarkerType, MiniMap, addEdge, applyEdgeChanges, applyNodeChanges } from "reactflow";
import TextUpdaterNode from "./ChildNode/TextNode";
import ImageNode from "./ChildNode/ImageNode";
import ButtonNode from "./ChildNode/ButtonNode";


function CustomTabPanel(props) {
    const { children, value, token, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const customStyles = {
    // Container style
    container: (provided) => ({
        ...provided,
        width: "200px",
        margin: "5px" // Adjust the width as needed
    }),
    // Control style (the input field)
    control: (provided) => ({
        ...provided,
        minHeight: "40px", // Adjust the height as needed
        borderRadius: "4px", // Add border-radius for a rounded look
        borderColor: "#ccc", // Set border color
    }),
    // Menu style
    menu: (provided) => ({
        ...provided,
        marginTop: "4px", // Add top margin
        borderRadius: "4px", // Add border-radius for a rounded look
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
    }),
    // Option style
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#f0f0f0" : "white", // Highlight selected option
        color: state.isSelected ? "#333" : "#666", // Adjust text color
        padding: "8px", // Add padding
    }),
};

// CustomTabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired
// };

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const initialNodes = [
    {
        id: 'A',
        data: { label: "Parent Node A" },
        position: { x: 0, y: 0 },
        type:"custom",
        style: {
            width: 270,
            height: 240,
        },
    },
    {
        id: 'B',
        type:"custom",
        data: { label: "Parent Node B" },
        position: { x: Math.random() * (100 - 10) + 10, y: Math.random() * (100 - 10) + 10 },
        style: {
            width: 270,
            height: 240,
        },
    },
    {
        id: 'A-1',
        type: 'text',
        data: { label: 'Child Node 1' },
        position: { x: Math.random() * (100 - 10) + 10, y: Math.random() * (100 - 10) + 10 },
        parentNode: 'A',
        sourceHandle:"A-1",
        extent: 'parent',
    },


];


const initialEdges = [
//    {
//     id: 'B->G',
//     source: 'A-1',
//     target: 'B',
//     style: {
//         strokeWidth: 2,
//         stroke: '#FF0072',
//       },
//       markerEnd: {
//         type: MarkerType.ArrowClosed,
//         width: 20,
//         height: 20,
//         color: '#FF0072',
//       },
//       sourceHandle:"A-1",
//     //   targetHandle:"B->G"
//     }
];
// const nodeTypes = {
//     text: (props) => <TextUpdaterNode props={props} onTextChange={handleTextChange} />,
// };
const rfStyle = {
    backgroundColor: '#D0C0F7',
};
const Whatsapp = ({ token, sidebarTheme, layoutTheme }) => {
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);
    const [sortedNodes, setSortedNodes] = useState([]);
    const [parentIdList, setParentIdList] = useState([])
    const [selectedParentNode, setSelectedParentNode] = useState()
    const [selectedChildType, setSelectedChildType] = useState()


    const handleTextChange = (nodeId, newText) => {
   
        console.log(`Text changed for adasdsad node ${nodeId}:`, newText);
    
        const newVarId = nodeId
        const newVarText = newText
        // Handle the new text value here
        // You can update your state or perform any other action based on the text change
    };

    const handleImageChange = (nodeId, newImageUrl) => {
        // Handle image change logic here
        // For example, update the state with the new image URL
        // setElements((prevElements) =>
        //     prevElements.map((el) =>
        //         el.id === nodeId ? { ...el, data: { ...el.data, imageUrl: newImageUrl } } : el
        //     )
        // );

        console.log(nodeId, newImageUrl )
    };

    const handleButtonTextChange = (nodeId,newText)=>{

        console.log(`Text changed for button ${nodeId}:`, newText);
    
        const newVarId = nodeId
        const newVarText = newText
    }

    const nodeTypes = useMemo(()=>({
        text: (props) => <TextUpdaterNode props={props} onTextChange={handleTextChange} />,
        image:(props) => <ImageNode props={props} onImageChange = {handleImageChange} />,
        button:(props) => <ButtonNode props={props} onButtonTextChange = {handleButtonTextChange} />
    }),[]) 

   
    // const { textUpdates } = useTextUpdater();

    // console.log('Text updates:', textUpdates);

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    // const onConnect = useCallback(
    //     (connection) => setEdges((eds) => addEdge(connection, eds)),
    //     [setEdges]
    // );
    const removeDuplicateSources = (edges) => {
        const seenSources = new Set();
        const uniqueEdges = edges.filter((edge) => {
            if (seenSources.has(edge.source)) {
                // Already seen this source, skip this edge
                return false;
            } else {
                seenSources.add(edge.source);
                return true;
            }
        });
    
        return uniqueEdges;
    };
    
    const onConnect = useCallback(
        (connection) => {
            console.log('on connect called', connection);
    
            // Check if any edge is already coming out from the source node
            const isSourceAlreadyConnected = edges.some(
                (edge) => ((edge.source === connection.source) || (edge.source === connection.target))
            );
            console.log(edges, "isSourceAlreadyConnected",nodes);
    
            if (isSourceAlreadyConnected) {
                console.log("You can only add one edge from one node.");
                return false;
            } else {
                // Create an object with desired edge properties
                const edgeToAdd = {
                    id: `${connection.target}-${connection.source}`,
                    source: connection.target,
                    target: connection.source,
                    style: {
                        strokeWidth: 2,
                        stroke: '#FF0072',  
                    },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 20,
                        height: 20,
                        color: '#FF0072',
                      },
                    sourceHandle : connection.source
                    // Add other properties as needed
                };
                console.log(edgeToAdd,"edge to add")
                // Add the edge to the array
                setEdges((prevEdges) => addEdge(edgeToAdd, edges));
                // const addedEdge = addEdge(edgeToAdd)
                // console.log(addedEdge,"Added edge")
    
                console.log("Edge added successfully");

                const newEdges = removeDuplicateSources(edges)
                console.log(newEdges,"checked for duplicate edges", edges,"old edges")
                return true;
            }
        },
        [edges, setEdges]
    );
    
    // useEffect(()=>{
    //     const newEdges = removeDuplicateSources(edges)
    //     console.log(newEdges,"checked for duplicate edges", edges,"old edsfsdfsdfsdfsdfdsges")
    //     setEdges(newEdges)
    // },[edges])



    function sortNodesById(nodesArray) {
        return nodesArray.sort((a, b) => {
            const idA = a.id.toUpperCase();
            const idB = b.id.toUpperCase();

            if (idA < idB) {
                return -1;
            }
            if (idA > idB) {
                return 1;
            }

            return 0;
        });
    }

    function getNextNodeId(sortedNodes) {
        if (sortedNodes.length === 0) {
            // If the array is empty, start with 'A'
            return 'A';
        }

        const lastNodeId = sortedNodes[sortedNodes.length - 1].id.toUpperCase();
        const nextCharCode = lastNodeId.charCodeAt(lastNodeId.length - 1) + 1;

        // Check if the last character is 'Z'
        if (nextCharCode > 'Z'.charCodeAt(0)) {
            // If 'Z', increment the previous character and append 'A'
            const prevCharCode = lastNodeId.charCodeAt(lastNodeId.length - 2) + 1;
            return String.fromCharCode(prevCharCode) + 'A';
        }

        // Otherwise, simply increment the last character
        return lastNodeId.slice(0, -1) + String.fromCharCode(nextCharCode);
    }

    useEffect(() => {
        setSortedNodes(sortNodesById(initialNodes));
        console.log(sortNodesById(initialNodes), ">>>>>>>>>>>>")
        const nextNode = getNextNodeId(sortedNodes);
        console.log(nextNode, "next node ");
    }, [initialNodes, sortedNodes]);

    const addParentNode = () => {
        const parentNode = {
            id: getNextNodeId(nodes),
            type:"input",
            data: { label: `Parent Node ${getNextNodeId(nodes)}` },
            position: { x: Math.random() * (100 - 10) + 10, y: Math.random() * (100 - 10) + 10 },
            style: {
                width: 270,
                height: 240,
            },
        };
        console.log(parentNode, "parent node to add")
        setNodes((prevNodes) => [...prevNodes, parentNode]);

        // Fix: Call sortNodesById on initialNodes
        setSortedNodes(sortNodesById([...nodes, parentNode]));

        // Add logic to update nodes with the new parent node
    };
    // console.log(nodes,"inital nodes after adding parent node")
    // console.log(getNextNodeId(nodes),"inital nodes after adding parent node")


    function extractParentIds(nodesArray) {
        const parentIdsArray = [];

        nodesArray.forEach((node) => {
            // Extract the parent ID from the node ID
            const parentId = node.id.split('-')[0];

            // Check if the parent ID is not already in the array before adding
            if (!parentIdsArray.some((item) => item.value === parentId)) {
                parentIdsArray.push({ value: parentId, label: parentId });
            }
        });

        return parentIdsArray;
    }



    useEffect(() => {

        setParentIdList(extractParentIds(nodes))
        // console.log(extractParentIds(nodes), "extracted nodes")
    }, [nodes])

    function getChildNodes(parentNodeId, nodesArray) {
        const childList = [];
        let nextId = null;

        nodesArray.forEach((node) => {
            if (node.id.startsWith(parentNodeId + '-')) {
                childList.push(node);
            }
        });

        // Find the next available child node ID
        const lastChildIndex = childList.length > 0 ? parseInt(childList[childList.length - 1].id.split('-')[1]) : 0;
        nextId = parentNodeId + '-' + (lastChildIndex + 1);

        console.log({ childList, nextId }, "return from this function")
        return { childList, nextId };
    }


    const addChildNode = () => {

        if (selectedChildType !== undefined && selectedParentNode !== undefined) {
            console.log("In parent", selectedParentNode, "we are adding child node", selectedChildType)

            let childType
            if(selectedChildType !== "text" || selectedChildType !== "image" || selectedChildType !== "button"){
                childType = "output"
            }

            const childNode = {
                parentNode: selectedParentNode,
                extent: "parent",
                id: getChildNodes(selectedParentNode, nodes).nextId,
                type: selectedChildType ? selectedChildType : childType,
                data: { label: `Child Node ${getChildNodes(selectedParentNode, nodes).nextId}` },
                position: { x: Math.random() * (100 - 10) + 10, y: Math.random() * (100 - 10) + 10 },

            }

            console.log(childNode, "child node to add")

            setNodes((prevNodes) => [...prevNodes, childNode]);

            // Fix: Call sortNodesById on initialNodes
            setSortedNodes(sortNodesById([...nodes, childNode]));

        }
        else {
            console.log("values are undefined")
        }
    }

    const nodeOptions = [
        { value: "text", label: "Text" },
        { value: "image", label: "Image" },
        { value: "button", label: "Button" },
    ];
    return (
        <div>
            <header>
                <div>Whatsapp bot</div>
                <Button color="primary" onClick={addParentNode}>Add Parent Node</Button>
                <div style={{ display: "flex" }}>
                    <Select
                        styles={customStyles}
                        options={parentIdList}
                        onChange={e => {
                            setSelectedParentNode(e.value);
                        }}
                    ></Select>
                    <Select
                        styles={customStyles}
                        onChange={e => {
                            setSelectedChildType(e.value);
                        }}
                        options={nodeOptions}
                    ></Select>

                    <Button onClick={() => {
                        addChildNode()
                    }}>Add Child Node</Button>
                </div>
            </header>
            <div style={{ width: "100%", height: "100vh" }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    fitView
                    nodeTypes={nodeTypes}
                    style={rfStyle}
                    attributionPosition="top-right"
                >
                    <Background />
                    <Controls />
                    <MiniMap
                        height="300px" />
                </ReactFlow>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger,
        token: state.auth.accessToken
    };
};

export default compose(connect(mapStateToProps)(Whatsapp));
