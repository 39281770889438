import React, { useCallback, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';

const handleStyle = { left: 10 };

function ImageNode({ props, isConnectable, onImageChange }) {
    const { data } = props;
    const [details, setDetails] = useState({});
    const [isTyping, setTyping] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState()

    useEffect(() => {
        if (data) {
            setDetails(data);
        }
    }, [data]);

    const onChange = useCallback((evt) => {
        setTyping(true);
        const newImageSrc = evt.target.value;
        setDetails((prevDetails) => ({ ...prevDetails, imageUrl: newImageSrc }));
        onImageChange(props.id, newImageSrc);
    }, [onImageChange]);

    const onImageUpoad = async (e)=>{

        const file = e.target.files[0]

        if(file){
            try {
                const imgUrl = URL.createObjectURL(file)
                console.log(imgUrl,"image url")
                setImagePreviewUrl(imgUrl)
            } catch (error) {
                console.log(error,"error while uploading image")
            }
        }
    }
    return (
        <div className="image-node" style={{ border: "2px solid blue", padding: "10px", margin: "10px" }}>
            {/* <Handle type="target" position={Position.Top} isConnectable={true} /> */}
            {/* <div>
                <label htmlFor="image">Image URL:</label>
                <input id="image" name="image" onChange={onChange} className="nodrag" />
            </div>
            <img src={details?.imageUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100px', marginTop: '10px' }} />
            Additional Handles or other elements can be added as needed */}

            <div>
                <label htmlFor='image'>Upload Image</label>
                <input id='image' type="file" name='image' onChange={(e)=>{onImageUpoad(e)}} className='nodrag' />
            </div>
            {imagePreviewUrl ? (
                <div>
                    <img src= {imagePreviewUrl} style={{height:"100px", width:"100px"}} alt='Preview Image' />
                     </div>
            ) : null}
        </div>
    );
}

export default ImageNode;
