import { Input, TextField } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import subscriptionAction from "redux/subscription/action";

const handleStyle = { left: 10 };

function CustomMultiSectionNode({
  props,
  text,
  setSelectedNode,
  setSidebarState,
  onTextChange,
  addExtraNode,
  deleteChildNode,
  changeHeight,
}) {
  // const{id,data} = props
  // const {data} = props

  const [childNodeId, setChildNodeId] = useState("");
  const [details, setDetails] = useState({
    title: "",
    description: [{ value: "" }],
  });
  const [textBoxHeight, setTextBoxHeight] = useState({
    title: "56",
    description: [{ height: "46" }],
  });

  const { success, error, fetching } = subscriptionAction;

  useEffect(() => {
    if (props?.id) {
      setChildNodeId(props?.id);
    }
    if (props?.data?.value?.title) {
      console.log(props.data.value,"props.data.value")
      setDetails(props?.data?.value);
    }
  }, []);
  const handleDeleteChildNode = () => {
    deleteChildNode(
      childNodeId,
      extractParentId(childNodeId),
      "customListSection"
    );
  };
  const onChange = useCallback(
    (evt, type) => {
      const newText = evt.target.value;
      console.log(newText, "EVENTS CHANGED VSLUE");

      // console.log(data,"Data. id")

      let data;
      if (type == "title" && newText.length > 24) {
        error("Max 24 characters are allowed");
        const truncatedText = newText.slice(0, 24);

        data = {
          ...details,
          [type]: truncatedText,
        };
        setDetails((oldData) => {
          return data;
        });
      } else if (type == "description" && newText.length > 72) {
        const truncatedText = newText.slice(0, 72);
        error("Max 72 characters are allowed");

        data = {
          ...details,
          [type]: truncatedText,
        };

        setDetails((oldData) => {
          return data;
        });
      } else {
        data = {
          ...details,
          [type]: newText,
        };

        console.log(data, "Data before setting");
        setDetails((oldData) => {
          return data;
        });
      }

      console.log({ details });
      const newHeight = evt.target.scrollHeight;

      const heightDifference = newHeight - textBoxHeight;
      console.log(
        newHeight,
        "newHeight",
        heightDifference,
        "heightDifference",
        textBoxHeight,
        "textBoxHeight"
      );
      setTextBoxHeight(newHeight);
      // console.log("after height update");
      console.log(data, "details before on text change");
      onTextChange(props.id, data, heightDifference);
    },
    [details, textBoxHeight, onTextChange, props.id]
  );

  // useEffect(() => {
  //   console.log({ textBoxHeight });
  // }, [textBoxHeight]);

  function extractParentId(childId) {
    // Split the childId by "-"
    const parts = childId.split("-");

    // If there is more than one part after splitting, it means it follows the pattern
    if (parts.length > 1) {
      // The first part is the parent id
      return parts[0];
    } else {
      // If there is only one part, it might be the parent id itself
      return childId;
    }
  }

  const newOnChange = (e, index,type) => {
    const newValue = e.target.value;

    const newHeight = e.target.scrollHeight;

    console.log(newHeight,"inside custom component")
    let tempData = Object.assign({}, details);


    if(type == "title"){

       tempData.title = newValue

       tempData ={
        ...tempData,
        title:newValue
       }

      setDetails(tempData);

      const heightDifference = newHeight - textBoxHeight.title


      let newHeightToAssign = Object.assign({}, textBoxHeight);

      newHeightToAssign = {
        ...newHeightToAssign,
        title: newHeight,
      };

      setTextBoxHeight(newHeightToAssign)

      onTextChange(props?.id, tempData, heightDifference);


    } else if(type == "description"){
      tempData.description[index] = {
        ...tempData.description[index],
        value: newValue,
      };
      setDetails(tempData);
      const heightDifference = newHeight - textBoxHeight?.description[index]?.height;

      let newHeightToAssign = Object.assign({}, textBoxHeight);

      newHeightToAssign.description[index] = {
        ...tempData.description[index],
        height: newHeight,
      };
  
      setTextBoxHeight(newHeightToAssign);
      onTextChange(props?.id, tempData, heightDifference);

    }
   

   
  };

  const deleteDescriptionNode = (index) => {
    setDetails((prevDetails) => {
      // Create a copy of the details object
      const newDetails = { ...prevDetails };
      // Create a copy of the description array
      const newDescription = [...newDetails.description];
      // Remove the element at the specified index
      newDescription.splice(index, 1);
      // Update the details object with the new description array
      newDetails.description = newDescription;
      // Return the updated details object
      return newDetails;
    });

    changeHeight(props?.id, false, "Multi");
  };

  const handleAddExtraNode = (type) => {
    console.log(type,"type in multi",extractParentId(childNodeId))
    addExtraNode(type, extractParentId(childNodeId));
  };
  const handleAddExtraSection = () => {
    const newDescriptions = [...details.description, { value: "" }]; // Add new empty description

    setDetails((prevState) => ({
      ...prevState,
      description: newDescriptions,
    }));

    const newHeight = [...textBoxHeight.description, { height: "46" }];

    setTextBoxHeight((prevState) => ({
      ...prevState,
      description: newHeight,
    }));

    console.log(props?.id, true, "Multi Console log");
    changeHeight(props?.id, true, "Multi");
  };
  return (
    <div
      className="nodrag position-relative"
      onClick={() => {
        setSelectedNode(extractParentId(childNodeId));
        // setSidebarState(true)
      }}
      //  className="text-updater-node"
    >
      <div
        className="flow-comn-close-btn"
        onClick={() => {
          handleDeleteChildNode();
        }}
        style={{ cursor: "pointer" }}
      >
        <i class="fas fa-times-circle"></i>
      </div>

      <div className="flow-inner-row">
        <div className="flow-inner-box">
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                placeholder="Title"
                maxRows={1}
                value={details.title}
                onChange={(e) => {
                  newOnChange(e,0, "title");
                }}
              />
              {/* <TextField
                size="large"
                multiline
                value={details.description}
                minRows={2}
                placeholder="Description"
                onChange={(e) => {
                  onChange(e, "description");
                }}
              /> */}
              {console.log(details, "console log", details.description?.length)}
              {Array.isArray(details.description) && details.description?.length > 0 &&
                details.description.map((desc, index) => {
                  return (  
                    <>
                      <div className="position-relative">
                        <div className="flow-comn-close-btn">
                          <i
                            style={{ fontSize: "10px", cursor: "pointer" }}
                            onClick={() => {
                              deleteDescriptionNode(index);
                            }}
                            className="fas fa-trash"
                          ></i>
                        </div>
                        <TextField
                          size="large"
                          multiline
                          value={desc?.value}
                          minRows={2}
                          placeholder="Product retailer id"
                          onChange={(e) => {
                            newOnChange(e, index,"description");
                          }}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
            {/* <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Handle type="source" className="flow-trigger"></Handle>
            </div> */}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "-18px",
              marginLeft: "5px",
              marginBottom: "5px",
              zIndex: "1",
              position: "relative",
            }}
          >
            <i
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                handleAddExtraSection();
              }}
              class="fas fa-plus-circle"
            ></i>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <i
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            handleAddExtraNode("customMultiSection");
          }}
          class="fas fa-plus-circle"
        ></i>
      </div>
    </div>
  );
}

export default CustomMultiSectionNode;
