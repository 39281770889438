import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    body: Yup.string().required("Body is required"),
    buttonTitle: Yup.string().required("Title is required"),
    buttonUrl: Yup.string().required("Url is required"),
  }),
  mapPropsToValues: (props) => ({
    body: "",
    buttonTitle: "",
    buttonUrl: "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
