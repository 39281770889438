// import { useCallback, useEffect, useState } from 'react';
// import { Handle, Position } from 'reactflow';

// const handleStyle = { left: 10 };

// function TextUpdaterNode({ data, isConnectable }) {

//     const [details, setDetails] = useState({})
//   const onChange = useCallback((evt) => {
//     console.log(evt.target.value);
//   }, []);


//   useEffect(()=>{

//     if(data){
//         setDetails(data)
//       }
//   },[])

//   return (
//     <div className="text-updater-node" style={{border:"2px solid black", padding:"10px", margin:"10px"}}>
//         <span style={{textAlign:"center"}}>{details?.name}</span>
//       <Handle type="target" position={Position.Top} isConnectable={true} />
//       <div>
//         <label htmlFor="text">Text:</label>
//         <input id="text" name="text" onChange={onChange} className="nodrag" />
//       </div>
//       {/* <Handle
//         type="source"
//         position={Position.Bottom}
//         id="a"
//         style={handleStyle}
//         isConnectable={isConnectable}
//       />
//       <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} /> */}
//     </div>
//   );
// }

// export default TextUpdaterNode;







// TextUpdaterNode.js
import { useCallback, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';

const handleStyle = { left: 10 };

function TextUpdaterNode({props, isConnectable, onTextChange }) {
   // const{id,data} = props 
    const {data} = props 
    const [details, setDetails] = useState({});
    const [isTyping,setTyping] = useState(false);
    console.log({props})
    console.log(data,"data directly",props.id,"id from props")

    useEffect(() => {

        if (data) {
            setDetails(data);
        }
    }, [data]);

    const onChange = useCallback((evt) => {
        setTyping(true)
        const newText = evt.target.value;
        setDetails((prevDetails) => ({ ...prevDetails, name: newText }));
        console.log(data,"Data. id")
       onTextChange(props.id, newText);
    }, [ onTextChange]);

    return (
        <div className="text-updater-node" style={{ border: "2px solid black", padding: "10px", margin: "10px" }}>
            {/* <span style={{ textAlign: "center" }}>{details?.name}</span> */}
            <Handle type="source" position={Position.Top} id= {props.id} isConnectable={true} />
            <div>
                <label htmlFor="text">Text:</label>
                <input id="text" name="text" onChange={onChange} className="nodrag" />
            </div>
            {/* <Handle
                type="source"
                position={Position.Bottom}
                id="a"
                style={handleStyle}
                isConnectable={isConnectable}
            />
            <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} /> */}
        </div>
    );
}

export default TextUpdaterNode;
