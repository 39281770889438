import { Input, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";

function DemoTry({
  props,
  text,
  setSelectedNode,
  setSidebarState,
  onTextChange,
}) {
 
    const [count,setCount] = useState(0)

    useEffect(()=>{
        // setCount(count=>count + 1)
        // setCount(count=>count + 1)
        // setCount(count=>count + 1)
        // setCount(count=>count + 1)
        setCount(count + 1)
        setCount(count + 1)
        setCount(count + 1)
        setCount(count + 1)

    },[])
  return (
   <>
   {count}</>
  );
}

export default DemoTry;
