import React, { Fragment } from "react";
import {
  Router,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import Layout from "layouts/DashboardLayout.jsx";
import {
  LockScreen,
  Login,
  Register,
  ForgotPassword,
} from "../src/views/pages/index";
// Redux store provider
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
// Style Root for making media queries to inline css
import { StyleRoot } from "radium";
// Layout Routes
import indexRoutes from "./routes/index";
// import layoutRoutes from "./routes/index.jsx";
import themes from "./settings/themes";
import { themeConfig } from "./settings";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/scss/app.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom.css";
import MainRoutes from "./routes/Routes";

// import "pretty-checkbox/src/pretty-checkbox.scss";

// const currentAppLocale =
//   AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const App = (props) => {
  return (
    <Fragment>
      {/* <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
      > */}
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <StyleRoot>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              {/*Start layout routes */}
              <BrowserRouter>
                <MainRoutes />
              </BrowserRouter>
              {/* 
      <Route  path="/login" element={<Login/>} />
      <Route  path="/lockscreen" element={<LockScreen/>} />
      <Route  path="/register" element={<Register/>} />
      <Route  path="/forgotPassword" element={<ForgotPassword/>} />
      <Route  path="/" element={<Layout/>} /> */}

              {/* <Route> */}
              {/* <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/dashboard" />}
                    />
                    {indexRoutes.map((prop, key) => {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                          history={history}
                        />
                      );
                    })}
                  </Switch> */}
              {/* <MainRoutes /> */}
              {/* </Route> */}

              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
              {/*End layout routes */}
            </PersistGate>
          </Provider>
        </StyleRoot>
      </ThemeProvider>
      {/* </IntlProvider> */}
    </Fragment>
  );
};

export default App;

// If you want to choose different color schema go to settings/index.jsx and set your theme and language.

// If you want to change sidebar nav list then go to util/data/sidebar.jsx
