import { Input, TextField } from "@mui/material";
import LoaderComponent from "components/common/LoaderComponent";
import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import subscriptionAction from "redux/subscription/action";
import defaultImageForPdf from "../../../../assets/images/pdf_placeholder_image.png";
import defaultImageForVideo from "../../../../assets/images/defaultVideoPic.png";

import { getImageUrl } from "services/flowServices";
const { success, error, fetching } = subscriptionAction;

const handleStyle = { left: 10 };

function CustomImageNode({
  props,
  text,
  setSelectedNode,
  setSidebarState,
  onTextChange,
  onImageUpload,
  companyId,
  setIsChildLoading,
}) {
  // const{id,data} = props
  // const {data} = props

  const [childNodeId, setChildNodeId] = useState("");
  const [details, setDetails] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [textValue, setTextValue] = useState("");
  const [textBoxHeight, setTextBoxHeight] = useState("46");
  const [heightDifference, setHeightDifference] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [fileName, setFileName] = useState("");

  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    if (props?.id) {
      setChildNodeId(props?.id);
    }
    if (props?.data?.value) {
      setTextValue(props?.data?.value);
      setSelectedFile(props?.data?.imageLink);
      setPreview(props?.data?.imageLink);
      setIsPdf(props?.data?.isPdf);
      setIsVideo(props?.data?.isVideo);
      setFileName(props?.data.fileName);
      console.log(props.data, "props dataa");
    }
  }, []);

  function extractParentId(childId) {
    // Split the childId by "-"
    const parts = childId.split("-");

    // If there is more than one part after splitting, it means it follows the pattern
    if (parts.length > 1) {
      // The first part is the parent id
      return parts[0];
    } else {
      // If there is only one part, it might be the parent id itself
      return childId;
    }
  }

  console.log({ companyId });
  // const handleImageUpload = async (event) => {
  //   try {
  //     setIsLoading(true);
  //     setIsChildLoading(true);
  //     const file = event.target.files[0];

  //     if (!file) {
  //       // No file selected
  //       return;
  //     }

  //     // Validate file type
  //     const allowedTypes = [
  //       "image/png",
  //       "image/jpeg",
  //       "image/jpg",
  //       "image/svg+xml",
  //     ];
  //     if (!allowedTypes.includes(file.type)) {
  //       error(
  //         "Invalid file type. Please select a .png, .jpg, .jpeg, .svg or .pdf file."
  //       );
  //       return;
  //     }

  //     // Validate file size
  //     const maxSize = 10 * 1024 * 1024; // 2MB
  //     if (file.size > maxSize) {
  //       error("File size exceeds 2MB limit. Please choose a smaller file.");
  //       return;
  //     }

  //     // Read the file and convert to base64
  //     const reader = new FileReader();
  //     reader.onloadend = async () => {
  //       const base64String = reader.result;
  //       setSelectedFile(file);
  //       setPreview(URL.createObjectURL(file));
  //       // You can now send the base64String to your backend

  //       const formData = new FormData();

  //       formData.append("file", file);
  //       formData.append("company_id", companyId);
  //       console.log("123123123123");

  //       const imageUrlRes = await getImageUrl(formData);

  //       console.log(imageUrlRes, "image url from s3");
  //       if (imageUrlRes?.status == "success") {
  //         setImageUrl(imageUrlRes?.url);
  //         onImageUpload(props?.id, imageUrlRes?.url);
  //         setIsLoading(false);
  //         setIsChildLoading(false);
  //       }
  //       // onImageUpload(props.id, base64String);
  //     };

  //     reader.readAsDataURL(file);
  //   } catch (error) {
  //     setIsLoading(false);
  //     setIsChildLoading(false);
  //     console.log(error, "error while trying to upload image");
  //   }
  // };

  const handleFileUpload = (event) => {
    try {
      setIsChildLoading(true);
      const file = event.target.files[0];

      if (!file) {
        // No file selected
        return;
      }

      // Validate file size
      const maxSize = 10 * 1024 * 1024; // 2MB
      if (file.size > maxSize) {
        error("File size exceeds 10 MB limit. Please choose a smaller file.");
        return;
      }
      setFileName((prevName) => file.name);
      console.log(file.name, "file name here");

      if (file.type.includes("image")) {
        // Handle image file
        handleImage(file, file.name);
      } else if (file.type === "application/pdf") {
        // Handle PDF file
        handlePDF(file, file.name);
      } else if (file.type.includes("video")) {
        handleVideo(file, file.name);
      } else {
        error(
          "Invalid file type. Please select an image (png, jpg, jpeg, svg) or a PDF file."
        );
      }
    } catch (error) {
      console.log(error, "error while trying to upload file");
      setIsChildLoading(false);
    }
  };

  const handleImage = (file, filename) => {
    // Validate file type
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
    ];
    if (!allowedTypes.includes(file.type)) {
      error(
        "Invalid file type. Please select a .png, .jpg, .jpeg, .svg or .pdf file."
      );
      return;
    }

    // Read the file and convert to base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
      setIsPdf(false);
      setIsVideo(false);
      // You can now send the base64String to your backend
      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);
      console.log("123123123123");

      const imageUrlRes = await getImageUrl(formData);

      console.log(imageUrlRes, "image url from s3");
      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        console.log(fileName, "fileName inside image upload function");
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
        };
        onImageUpload(props?.id, dataToSend, "image");
        setIsLoading(false);
        setIsChildLoading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  const handlePDF = (file, filename) => {
    // Read the file and convert to base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setSelectedFile(file);
      // Set a default preview image for PDF files or display a PDF icon
      setPreview(defaultImageForPdf);
      // You can now send the base64String to your backend
      setIsPdf(true);
      setIsVideo(false);

      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);
      console.log("123123123123");

      const imageUrlRes = await getImageUrl(formData);

      console.log(imageUrlRes, "image url from s3");
      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
        };
        onImageUpload(props?.id, dataToSend, "pdf");
        setIsLoading(false);
        setIsChildLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleVideo = (file, filename) => {
    const allowedVideoTypes = [
      "video/mp4",
      "video/mpeg",
      "video/webm",
      "video/quicktime",
      "video/x-msvideo",
    ];

    if (!allowedVideoTypes.includes(file.type)) {
      error(
        "Invalid file type. Please select a .png, .jpg, .jpeg, .svg or .pdf file."
      );
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setSelectedFile(file);
      // Set a default preview image for PDF files or display a PDF icon
      setPreview(defaultImageForVideo);
      // You can now send the base64String to your backend
      setIsPdf(false);
      setIsVideo(true);

      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      console.log(imageUrlRes, "image url from s3");
      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
        };
        onImageUpload(props?.id, dataToSend, "video");
        setIsLoading(false);
        setIsChildLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };

  const onChange = (evt) => {
    const newText = evt.target.value;
    setDetails((prevDetails) => ({ ...prevDetails, name: newText }));
    // console.log(data,"Data. id")
    const newHeight = evt.target.scrollHeight;

    //let heightDifference
    // if(newHeight<textBoxHeight){
    //   heightDifference = newHeight - textBoxHeight
    // }
    // else if(newHeight > textBoxHeight){
    //   heightDifference = newHeight - textBoxHeight
    // }else{
    //   heightDifference = 0
    // }
    const heightDifference = newHeight - textBoxHeight;
    console.log(
      newHeight,
      "newHeight",
      heightDifference,
      "heightDifference",
      textBoxHeight,
      "textBoxHeight"
    );
    setTextBoxHeight(newHeight);
    setTextValue(newText);
    // console.log("after height update");
    onTextChange(props.id, newText, heightDifference);
  };
  return (
    <>
      <div
        className="nodrag"
        onClick={() => {
          setSelectedNode(extractParentId(childNodeId));
          // setSidebarState(true)
        }}
      >
        <div className="flow-inner-row">
          <div style={{ height: "200px" }} className="flow-inner-box">
            {fileName !== "" ? (
              <>
              <div className="flow-file-input"
              >
              <i class="fas fa-image"></i>
              <label htmlFor="fileuploadname" className="file-upload-name-label">{fileName}</label>
              <input type="file" id="fileuploadname" className="file-upload-name-input"
              onChange={(e) => {
                      handleFileUpload(e);
                    }} />

              </div>
              </>
            ) : (
              <>
                <div className="flow-file-input">
                  <i class="fas fa-image"></i>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                </div>
              </>
            )}
 {/* <div className="flow-file-input">
                  <i class="fas fa-image"></i>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                </div> */}
            {preview && (
              <div className="flow-image-preview-box">
                {console.log(isPdf, "ppdf", isVideo, "is video")}

               
                <img
                  src={
                    isPdf == true
                      ? defaultImageForPdf
                      : isVideo == true
                      ? defaultImageForVideo
                      : preview
                  }
                />
              </div>
            )}
          </div>
          <div className="flow-inner-box nodrag">
            <TextField
              className="nodrag"
              size="large"
              multiline
              value={textValue}
              placeholder="Enter your body here"
              minRows={2}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomImageNode;
