import { useCallback, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';

const handleStyle = { left: 10 };

function ButtonNode({props, isConnectable, onButtonTextChange }) {
   // const{id,data} = props 
    const {data} = props 
    const [details, setDetails] = useState({});
    const [isTyping,setTyping] = useState(false);
    console.log({props})
    console.log(data,"data directly",props.id,"id from props")

    useEffect(() => {

        if (data) {
            setDetails(data);
        }
    }, [data]);

    const onChange = useCallback((evt) => {
        setTyping(true)
        const newText = evt.target.value;
        setDetails((prevDetails) => ({ ...prevDetails, name: newText }));
        console.log(data,"Data. id")
       onButtonTextChange(props.id, newText);
    }, [ onButtonTextChange]);

    return (
        <div className="text-updater-node" style={{ border: "2px solid black", padding: "10px", margin: "10px" }}>
            {/* <span style={{ textAlign: "center" }}>{details?.name}</span> */}
            <Handle type="target" position={Position.Top} isConnectable={true} />
            <div>
                <label htmlFor="text">Button Text:</label>
                <input id="text" name="text" onChange={onChange} className="nodrag" />
            </div>
            {/* <Handle
                type="source"
                position={Position.Bottom}
                id="a"
                style={handleStyle}
                isConnectable={isConnectable}
            />
            <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} /> */}
        </div>
    );
}

export default ButtonNode;
