import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    body: Yup.string().required("Body is required"),
    catalogueId: Yup.string().required("Catalogue Id is required"),
    sections: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Section Title is required"),
        retailerId: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.string().required("Product is Required"),
              mediaUrl: Yup.string().required("Media is Required"),
              name: Yup.string().required("Name is Required"),
              description: Yup.string().required("Description is Required"),
              retailerId: Yup.string().required("RetialerId is Required"),
              price: Yup.string().required("Price is Required"),
            })
          )
          .required("At least one Retailer Id is required"),
      })
    ),
  }),
  mapPropsToValues: (props) => ({
    body: "",
    title: "",
    sections: [],
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
