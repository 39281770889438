import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Layout from "layouts/DashboardLayout.jsx";
import { indexRoutes } from "./index";
import {
  Router,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useLocation,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

// import { Link } from "react-router-dom";
import {
  LockScreen,
  Login,
  Register,
  ForgotPassword,
} from "../views/pages/index";
import { compose } from "redux";
import authActions from "redux/auth/actions";
// import { history } from "./redux/store";
// Layout Routes
import layoutRoutes from "./index.jsx";
import navigationAction from "redux/navigation/actions";
import { checkApi } from "services/authServices";
import Loader from "components/Loader";
import TaxiServices from "views/pages/Services/services";
import ResetPassword from "views/pages/authentication/ResetPassword";
import Whatsapp from "views/pages/Whatsapp/Whatsapp";
import Try from "views/pages/Trying/Try";
import DemoTry from "views/pages/DemoTry";
import MainFile from "views/pages/Final/MainFile";
import ReactFlow, { ReactFlowProvider } from "reactflow";
// import { Dashboard } from "views/app/index.jsx";
// import ProtectedRoutes from "./protectedRoutes";
const { check, logout, setUser } = authActions;
const { success, error, getNotificationData } = navigationAction;

const Dashboard = React.lazy(() =>
  import("../views/pages/dashboards/Dashboard")
);

const TaxiStation = React.lazy(() =>
  import("../views/pages/Services/serviceStation")
);

const Profile = React.lazy(() =>
  import("../views/pages/authentication/profile")
);

const Inquiry = React.lazy(() => import("../views/pages/enquiry/enquirylist"));
// const UserProfile = React.lazy(() =>
//   import("../views/pages/authentication/UserProfile")
// );

const ReactRoutes = (props) => {
  const { check, user_id, token } = props;
  // const token = localStorage.getItem('accessToken')

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const excludePath = [
    "login",
    "register",
    "forgotPassword",
    "admin",
    "user",
    "merchant",
    "login_as",
  ];

  const checkLogin = async () => {
    const data = { id: user_id, token: token };
    try {
      const response = await checkApi(token, data); // You need to define checkApi function
      if (response.success) {
        check(response.data);
        setUser(response.data);
        success();
      } else {
        logout(token);
        error();
      }
    } catch (error) {
      console.error("Error while checking login:", error);
    }
  };

  // useEffect(() => {
  //   if (!excludePath.includes(location.pathname.split("/")[1])) {
  //       checkLogin();
  //     setIsLoading(false);
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, []);
  // const navigate = useNavigate();
  // console.log(window.location.pathname,">>/")
  // if (token && window.location.pathname === '/') {
  //   console.log('goo in iff')
  //   navigate('dashboard');
  // }
  // const redirect = () =>{
  //   navigate('dashboard')
  // }

  return !isLoading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        {/* <Route
          path="/login"
          element={
            <React.Suspense fallback={<Loader />}>
              <Dashboard />
            </React.Suspense>
          }
        /> */}

        {/* <Route
          path="/whatsapp"
          element={
            <React.Suspense fallback={<Loader />}>
              <Whatsapp />
            </React.Suspense>
          }
        /> */}

        <Route
          path="/flow"
          element={
            <React.Suspense fallback={<Loader />}>
              <ReactFlowProvider>
                <MainFile />
              </ReactFlowProvider>
            </React.Suspense>
          }
        />
        {/* <Route
          path="/Testing"
          element={
            <React.Suspense fallback={<Loader />}>
              <DemoTry />
            </React.Suspense>
          }
        /> */}

        {/* <Route
          path="/register"
          element={token ? <Navigate to="/Dashboard" /> : <Register />}
        />
        <Route
          path="/lockscreen"
          element={token ? <Navigate to="/Dashboard" /> : <LockScreen />}
        />
        <Route
          path="/forgotPassword"
          element={token ? <Navigate to="/Dashboard" /> : <ForgotPassword />}
        />
        <Route path="/reset-password" element={<ResetPassword />} /> */}
      </Routes>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.accessToken,
  user: state.auth.user,
  user_id: state.auth.user_id,
});

export default compose(
  connect(mapStateToProps, {
    check,
    logout,
    success,
    error,
    getNotificationData,
    setUser,
  })
)(ReactRoutes);
