import { InputLabel, MenuItem, Select } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import { getTemplateData, submitSelectedTemplate } from "services/flowServices";
import defaultImageForPdf from "../../../../assets/images/pdf_placeholder_image.png";
import defaultImageForVideo from "../../../../assets/images/defaultVideoPic.png";
import subscriptionAction from "redux/subscription/action";
import { getImageUrl } from "services/flowServices";

const { success, error, fetching } = subscriptionAction;

const handleStyle = { left: 10 };

function CustomTemplateNode({
  props,
  setSidebarState,
  setSelectedNode,
  onImageUpload,
  changeHeight,
  setIdForTemplate,
  setIsChildLoading,
}) {
  const [parentId, setParentId] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOptions, setDropDownOptions] = useState([]);
  const [responseForTemplate, setResponseForTemplate] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [isPdf, setIsPdf] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  console.log("company id setting test");
  useEffect(() => {
    if (props?.data) {
      setSelectedOption(props?.data?.id);

      setIsPdf(props?.data?.isPdf);
      if (props?.data?.isPdf) {
        setPreview(defaultImageForPdf);
        selectedFile(props?.data?.imageLink);
      } else {
        setPreview(props?.data?.imageLink);
      }
    }
    if (companyId) {
      console.log(companyId, "companyId inside use effect responseForTemplate");
      handleGetDropdownData();
    }
  }, [companyId]);

  useEffect(() => {
    console.log(responseForTemplate, "responseForTemplate");
    changeHeight(props?.id, responseForTemplate, "template");

    if (responseForTemplate == false) {
      console.log("responseForTemplate is fasle");
      setIsPdf(false);
      setPreview(null);
      setSelectedFile(null);
    }
  }, [responseForTemplate]);

  useEffect(() => {
    if (props?.id) {
      setParentId(props?.id);
    }
    const searchParams = new URLSearchParams(window.location.search);
    setCompanyId((prev) => {
      console.log("inside callback company id setting");
      return searchParams.get("company_id") || "";
    });
    console.log(searchParams.get("company_id"), "company id setting");

    console.log("company id setting", props);
  }, []);

  const submitSelectedOption = async (option) => {
    try {
      const res = await submitSelectedTemplate(option);
      if (res.status == "success") {
        console.log(res, "res from template api responseForTemplate");
        setResponseForTemplate(res?.data);
        setPreview(null);
        setSelectedFile(null);
      } else {
        console.log(res, "Something went wrong");
      }
    } catch (error) {
      console.log(error, "error while trying to submit selected template");
    }
  };

  useEffect(() => {
    if (selectedOption) {
      submitSelectedOption(selectedOption);
    }
  }, [selectedOption]);

  const handleGetDropdownData = async () => {
    try {
      console.log(companyId, "companyId inside function");
      const res = await getTemplateData(companyId);
      if (res.status == "success") {
        console.log(res, "res from dropdown api");
        setDropDownOptions(res.data);
      } else {
        console.log(res, "Something went wrong");
      }
    } catch (error) {
      console.log(error, "error while trying to get data for dropdown");
    }
  };

  const handleValueChange = (e) => {
    console.log(e.target.value, "e in dropdown");

    if (e.target.value) {
      setSelectedOption(e.target.value);
      // submitSelectedOption(e.target.value);

      setIdForTemplate(props?.id, e.target.value);
    }
  };

  const handleFileUpload = (event) => {
    try {
      setIsChildLoading(true);
      const file = event.target.files[0];

      if (!file) {
        // No file selected
        return;
      }

      // Validate file size
      const maxSize = 15 * 1024 * 1024; // 15MB
      if (file.size > maxSize) {
        error("File size exceeds 15MB limit. Please choose a smaller file.");
        return;
      }

      if (responseForTemplate === "DOCUMENT") {
        if (file.type !== "application/pdf") {
          error("Invalid file type. Please select a PDF file.");
          return;
        }
      } else if (responseForTemplate === "IMAGE") {
        const allowedImageTypes = [
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg+xml",
        ];
        if (!allowedImageTypes.includes(file.type)) {
          error(
            "Invalid file type. Please select a .png, .jpg, .jpeg, or .svg file."
          );
          return;
        }
      } else if (responseForTemplate === "VIDEO") {
        const allowedVideoTypes = [
          "video/mp4",
          "video/mpeg",
          "video/webm",
          "video/quicktime",
          "video/x-msvideo",
        ];

        if (!allowedVideoTypes.includes(file.type)) {
          error(
            "Invalid file type. Please select a .mp4, .mpeg, .webm, .quicktime, or .x-msvideo file."
          );
          return;
        }
      }

      if (file.type.includes("image")) {
        // Handle image file
        handleImage(file, file.name);
      } else if (file.type === "application/pdf") {
        // Handle PDF file
        handlePDF(file, file.name);
      } else if (file.type.includes("video")) {
        handleVideo(file, file.name);
      } else {
        error(
          "Invalid file type. Please select an image (png, jpg, jpeg, svg) or a PDF file."
        );
      }
    } catch (error) {
      console.log(error, "error while trying to upload file");
    }
  };

  const handleImage = (file, filename) => {
    // Validate file type
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
    ];
    if (!allowedTypes.includes(file.type)) {
      error(
        "Invalid file type. Please select a .png, .jpg, .jpeg, .svg or .pdf file."
      );
      return;
    }

    // Read the file and convert to base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
      setIsPdf(false);
      // You can now send the base64String to your backend
      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      console.log(imageUrlRes, "image url from s3");
      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
        };
        onImageUpload(props?.id, dataToSend, "image");
        setIsLoading(false);
        setIsChildLoading(false);
        // onImageUpload(props.id, base64String, "image");
      }
    };

    reader.readAsDataURL(file);
  };

  const handlePDF = (file, filename) => {
    // Read the file and convert to base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setSelectedFile(file);
      // Set a default preview image for PDF files or display a PDF icon
      setPreview(defaultImageForPdf);
      // You can now send the base64String to your backend
      setIsPdf(true);

      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      console.log(imageUrlRes, "image url from s3");
      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
        };
        onImageUpload(props?.id, dataToSend, "pdf");
        setIsLoading(false);
        setIsChildLoading(false);
        // onImageUpload(props.id, base64String, "image");
      }
    };
    reader.readAsDataURL(file);
  };

  const handleVideo = (file, filename) => {
    const allowedVideoTypes = [
      "video/mp4",
      "video/mpeg",
      "video/webm",
      "video/quicktime",
      "video/x-msvideo",
    ];

    if (!allowedVideoTypes.includes(file.type)) {
      error(
        "Invalid file type. Please select a .png, .jpg, .jpeg, .svg or .pdf file."
      );
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setSelectedFile(file);
      // Set a default preview image for PDF files or display a PDF icon
      setPreview(defaultImageForVideo);
      // You can now send the base64String to your backend
      setIsPdf(false);
      setIsVideo(true);

      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      console.log(imageUrlRes, "image url from s3");
      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
        };
        onImageUpload(props?.id, dataToSend, "video");
        setIsLoading(false);
        setIsChildLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      className="nodrag position-relative"
      onClick={() => {
        //   setSelectedNode(extractParentId(childNodeId));
        // setSidebarState(true)
      }}
      //  className="text-updater-node"
    >
      {/* <div className="flow-comn-close-btn" style={{ cursor: "pointer" }}>
        <i class="fas fa-times-circle"></i>
      </div> */}

      <div
        className="flow-inner-row"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="flow-inner-box">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <InputLabel id={`demo-simple-select-label-${parentId}`}>
                Template
              </InputLabel>

              <Select
                style={{ width: "200px" }}
                labelId={`demo-simple-select-label-${parentId}`}
                id="demo-simple-select"
                value={selectedOption}
                label="Age"
                onChange={(e) => handleValueChange(e)}
              >
                {dropdownOptions && dropdownOptions.length > 0 ? (
                  dropdownOptions.map((option) => {
                    return (
                      <MenuItem value={option?.id}>{option?.title}</MenuItem>
                    );
                  })
                ) : (
                  <MenuItem disabled>Nothing To Select</MenuItem>
                )}
              </Select>
            </div>
            {/* <div
            style={{ display: "flex", alignItems: "center", margin: "5px" }}
          >
            <Handle type="source" className="flow-trigger"></Handle>
          </div> */}

            {responseForTemplate !== false ? (
              <div style={{ height: "200px" }} className="flow-inner-box mt-3">
                <div className="flow-file-input">
                  <i class="fas fa-image"></i>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                </div>
                {preview && (
                  <div className="flow-image-preview-box">
                    {console.log(isPdf, "ppdf", isVideo, "is video")}

                    <img
                      src={
                        isPdf == true
                          ? defaultImageForPdf
                          : isVideo == true
                          ? defaultImageForVideo
                          : preview
                      }
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomTemplateNode;
