import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import enhancer from "../../enhancers/ServicesEnhancer";
import { compose } from "redux";
import { addService, editService } from "services/taxiServices";
import { useEffect } from "react";
import RoleActions from "redux/services/action";
import { Country, State, City } from "country-state-city";

const { success, error, fetching } = RoleActions;

const AddEdidTaxiService = props => {
  const BACKEND_URI = process.env.REACT_APP_BACKEND_URI_UPLOAD;

  console.log(Country.getAllCountries(), "country");
  const { isEdit, editData, onClose } = props;
  const options = [
    { value: true, label: "Active" },
    { value: false, label: "Deactive" }
  ];
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    token,
    isValid,
    setValues,
    handleSubmit,
    toggleRefresh,
    setFieldValue
  } = props;

  const [loading, setLoading] = useState(false);

  const [imgPreview, setimgPreview] = useState();
  const [IsPreview, setPreview] = useState(false);

  useEffect(() => {
    isEdit && setValues({ ...editData });
    setimgPreview(editData.file_image);
  }, [editData]);

  const AddRoleModalHandler = async e => {
    e.preventDefault();
    handleSubmit();
    if (!isValid) {
      return;
    }

    const data = new FormData();

    data.append("name", values.name.toLowerCase());
    data.append("file_image", values.file_image);
    data.append("description", values.description);
    setLoading(true);
    isEdit
      ? await editService(token, values._id, data).then(res => {
          if (res.success) {
            success(res.message);
            toggleRefresh(true);
            setLoading(false);
            onClose();
          } else {
            error(res.message);
            setLoading(false);
          }
        })
      : await addService(token, data).then(res => {
          if (res.success) {
            success(res.message);
            setLoading(false);
            toggleRefresh(true);
            onClose();
          } else {
            error(res.message);
            setLoading(false);
            toggleRefresh(true);
          }
        });
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Taxi Service
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group">
            <label>
              Name <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Name "
            />
            <Error field="name" />
          </div>

          <div className="form-group">
            <label>
              Description{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              placeholder="Description "
            />
            <Error field="description" />
          </div>

          <div className="form-group">
            <label>
              Logo <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <div className="d-flex flex-row">
              <input
                type="file"
                id="file_image"
                className="form-control react-form-input"
                accept=".jpg,.png,.jpeg,.svg"
                onChange={e => {
                  if (e.target.files[0]) {
                    setFieldValue("file_image", e.target.files[0]);
                    const imagePreviewUrl = URL.createObjectURL(
                      e.target.files[0]
                    );
                    setimgPreview(imagePreviewUrl);
                    setPreview(true);
                  }
                }}
                onBlur={handleBlur}
              />
            </div>
            <Error field="file_image" />
            <div>
              {isEdit && !IsPreview ? (
                <a
                  href={`${BACKEND_URI}/${imgPreview}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="h-80 d-inline-block rounded-0">
                    <img
                      src={`${BACKEND_URI}/${imgPreview}`}
                      alt="..."
                      className="rounded-0 previewImage"
                    />
                  </div>
                </a>
              ) : (
                IsPreview && (
                  <a
                    href={`${imgPreview}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="h-80 d-inline-block rounded-0">
                      <img
                        src={imgPreview}
                        className="rounded-0 previewImage"
                      />
                    </div>
                  </a>
                )
              )}
            </div>
          </div>

          <div className="row justify-content-center">
            <button
              type="submit"
              onClick={e => AddRoleModalHandler(e)}
              className="btn chargeButton mx-3 btnsize"
            >
              Save
            </button>
            <button
              type="submit"
              className="btn btn-secondary btnsize"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </ModalBody>
      {/* {loading ? <Loader /> : ""} */}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  enhancer,
  connect(mapStateToProps, { success, error, fetching })
)(AddEdidTaxiService);
