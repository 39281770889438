import { api, handleResponse, handleError } from "./apiServices";

export const loginApi = data =>
  api()
    .post("/api/taxi/auth/loginTaxiMerchant", data)
    .then(handleResponse)
    .catch(handleError);

export const registerApi = data =>
  api()
    .post("/api/taxi/auth/registerTaxiMerchant", data)
    .then(handleResponse)
    .catch(handleError);

export const verifyTaxiMerchant = data =>
  api()
    .post("/api/taxi/auth/verifyTaxiMerchant", data)
    .then(handleResponse)
    .catch(handleError);

export const logoutApi = token =>
  api(token)
    .get("/admin/auth/logout")
    .then(handleResponse)
    .catch(handleError);

export const getTaxiProfile = token =>
  api(token)
    .get("api/taxi/auth/profile")
    .then(handleResponse)
    .catch(handleError);

export const changePasswordMerchant = (token, data) =>
  api(token)
    .post("/api/taxi/auth/changePassword", data)
    .then(handleResponse)
    .catch(handleError);

export const checkApi = (token, data) =>
  api(token, data)
    .post("/api/admin/auth/check")
    .then(handleResponse)
    .catch(handleError);

export const editProfile = (token, data) =>
  api(token)
    .put("/api/taxi/auth/editProfile", data)
    .then(handleResponse)
    .catch(handleError);
