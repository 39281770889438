import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    body: Yup.string().required("Body is required"),
    title: Yup.string().required("List title is required"),
    sections: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Section Title is required"),
        item: Yup.array()
          .of(
            Yup.object().shape({
              //   index: Yup.string().required(),
              itemTitle: Yup.string().required("Item Title is required"),
              itemDescription: Yup.string().nullable(),
            })
          )
          .required("At least one Retailer Id is required"),
      })
    ),
  }),
  mapPropsToValues: (props) => ({
    body: "",
    title: "",
    sections: [],
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
