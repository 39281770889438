import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    textButtons: Yup.array().of(
      Yup.object().shape({
        header: Yup.string().nullable(),
        body: Yup.string().required("Body is required"),
        footer: Yup.string().nullable(),
        buttons: Yup.array().of(
          Yup.object().shape({
            title: Yup.string().required("Title is required"),
            uniqueId: Yup.string().required("Unique ID is required"),
          })
        ),
      })
    ),
    mediaButtons: Yup.array().of(
      Yup.object().shape({
        file: Yup.mixed().nullable(),
        fileUrl: Yup.string()
          .url("Invalid URL")
          .required("Media required"),
        fileName: Yup.string(),
        isImage: Yup.boolean(),
        isVideo: Yup.boolean(),
        isPdf: Yup.boolean(),
        body: Yup.string().required("Body is required"),
        buttons: Yup.array().of(
          Yup.object().shape({
            title: Yup.string().required("Title is required"),
            uniqueId: Yup.string().required("Unique ID is required"),
          })
        ),
      })
    ),
    textLists: Yup.array().of(
      Yup.object().shape({
        header: Yup.string().nullable(),
        footer: Yup.string().nullable(),
        listTitle: Yup.string().required("List title is required"),
        body: Yup.string().required("Body is required"),
        sections: Yup.array().of(
          Yup.object().shape({
            sectionTitle: Yup.string().required("Section title is required"),
            items: Yup.array().of(
              Yup.object().shape({
                itemTitle: Yup.string().required("Item title is required"),
                itemDescription: Yup.string().nullable(),
              })
            ),
          })
        ),
      })
    ),
    texts: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("Text Field Can't Be Empty"),
      })
    ),
  }),
  mapPropsToValues: () => ({
    indexing: [
      {
        index: 1,
        type: "textButtons",
        localIndex: 0,
      },
      {
        index: 2,
        type: "mediaButtons",
        localIndex: 0,
      },
      {
        index: 3,
        type: "textLists",
        localIndex: 0,
      },
    ],
    textButtons: [
      {
        index: 1,
        header: "",
        body: "",
        footer: "",
        buttons: [
          {
            title: "",
            uniqueId: "pvmr6sHBVI",
          },
        ],
      },
    ],
    mediaButtons: [
      {
        index: 1,
        file: null,
        fileUrl: "",
        fileName: "",
        isImage: false,
        isVideo: false,
        isPdf: false,
        body: "",
        buttons: [
          {
            title: "",
            uniqueId: "x9y1GT5z5N",
          },
        ],
      },
    ],
    textLists: [
      {
        index: 1,
        header: "",
        footer: "",
        listTitle: "",
        body: "",
        sections: [
          {
            sectionTitle: "",
            items: [
              {
                itemTitle: "",
                itemDescription: "",
              },
            ],
          },
        ],
      },
    ],
  }),
  handleSubmit: (values) => {
    console.log(values);
  },
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
