import { CheckBox } from "@mui/icons-material";
import { Select, Switch, TextField } from "@mui/material";
import React, { useState } from "react";

const PaymentNode = ({ props, deleteParentNode }) => {
  const [allValues, setAllValues] = useState({
    templateId: "",
    currency: "",
    isCustomProduct: false,
    productName: "",
    productQuantity: "",
    paymentAmount: "",
  });
  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  console.log(allValues, "all values in here");
  return (
    <div className="text-updater-node flow-comn-box">
      <div className="flow-title-box">
        <aside>Message</aside>
        {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
        <div onClick={handleDeleteParentNode} className="preview-eye-button">
          <i className="fas fa-trash"></i>
        </div>
      </div>
      <div>
        <div>
          select for template
          <Select></Select>
        </div>
        <div>
          select for currency
          <Select></Select>
        </div>
        <div style={{ margin: "30px" }}>
          Custom Product
          <Switch
            value={allValues?.isCustomProduct}
            onChange={(e) => {
              console.log(e.target.value, "e here");

              setAllValues((prevValues) => {
                let temp = !prevValues.isCustomProduct;
                prevValues = {
                  ...prevValues,
                  isCustomProduct: temp,
                };

                return prevValues;
              });
            }}
          />
        </div>
      </div>
      {allValues?.isCustomProduct == "true" ? (
        <>
          <div>
            <div>
              <TextField label="Product Name" />
            </div>
            <div>
              <TextField label="Product Quantity" />
            </div>
            <div>
              <TextField label="Payment Amount (Per Product)" />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PaymentNode;
