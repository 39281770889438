import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    id: Yup.string().required("Please select an option"),
    variables: Yup.array().of(
      Yup.object().shape({
        dropdownValue: Yup.string().required(" Please select an option"),
        textValue: Yup.string().required(" Please select an option"),
        id: Yup.string().required(" Please select an option"),
      })
    ),
    carousel: Yup.array().of(
      Yup.object().shape({
        fileName: Yup.string().required(" Please select an option"),
        fileType: Yup.string().required(" Please select an option"),
        fileUrl: Yup.string().required("Media Required"),
        variables: Yup.array().of(
          Yup.object().shape({
            dropdownValue: Yup.string().required(" Please select an option"),
            textValue: Yup.string().required(" Please select an option"),
            id: Yup.string().required(" Please select an option"),
          })
        ),
      })
    ),
  }),
  mapPropsToValues: (props) => ({
    id: "",
    variables: [],
    carousel: [],
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
