import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    attributeId: Yup.string().required("Please select an attribute"),
    value: Yup.string().required("Please fill this field"),
  }),
  mapPropsToValues: (props) => ({
    attributeId: "",
    value: "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
