import React, { useEffect, useState } from "react";
import { Button, TextField, Menu, MenuItem, Input } from "@mui/material";
import { Handle, Position, useStore } from "reactflow";
import { getImageUrl } from "services/flowServices";
import subscriptionAction from "redux/subscription/action";
import defaultImageForPdf from "../../../../assets/images/pdf_placeholder_image.png";
import defaultImageForVideo from "../../../../assets/images/defaultVideoPic.png";
import Enhancer from "../Enhancers/NewCommonNodeEnhancer";
import { Formik } from "formik";
const connectionNodeIdSelector = (state) => state.connectionNodeId;

const NewCommonNode = ({
  props,
  onTextChange,
  setSelectedNode,
  deleteParentNode,
  setPreviewState,
  setIsChildLoading,
  handleBlur,
  companyId,
  touched,
  errors,
  values,
  setErrorsArray,
  setValues,
}) => {
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;
  const { success, error, fetching } = subscriptionAction;

  const [isLoading, setIsLoading] = useState(false);
  const [loadingState, setLoadingState] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setMenuOpen(!menuOpen);
    setAnchorEl(event.currentTarget);
  };
  const [allValues, setAllValues] = useState({
    indexing: [
      {
        index: 1,
        type: "mediaButtons",
        localIndex: 0,
      },
    ],
    textButtons: [],
    mediaButtons: [
      {
        index: 1,
        file: null,
        fileUrl: "",
        fileName: "",
        isImage: false,
        isVideo: false,
        isPdf: false,
        body: "",
        buttons: [],
      },
    ],
    textLists: [],
    texts: [],
  });

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  useEffect(() => {
    if (Object.keys(errors)?.length !== 0) {
      setErrorsArray((arr) => {
        const index = arr.findIndex((x) => x?.id === props?.idb);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      setErrorsArray((arr) => {
        const filteredArr = arr.filter((x) => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
    return () => {};
  }, [allValues, errors]);

  useEffect(() => {
    if (props?.data?.value) {
      setAllValues(props?.data?.value);
      onTextChange(props?.id, props?.data?.value);
      setValues(props?.data?.value);
    }
  }, []);

  const handleAddContent = (type, obj) => {
    try {
      let temp = { ...allValues };

      temp[type] = [...temp[type], obj];

      const anotherTemp = {
        index: temp?.indexing.length + 1,
        type,
        localIndex: obj.index - 1,
      };
      temp["indexing"] = [...temp?.indexing, anotherTemp];
      setAllValues(temp);
    } catch (error) {
      console.log(error, "Error while trying to add content to screen");
    }
  };

  const handleRemoveOptionsField = (field, id) => {
    try {
      const optionsIndex = allValues[field.type][field.localIndex][
        "options"
      ].findIndex((x) => x.id === id);

      let temp = { ...allValues };
      let data = [...temp[field.type][field.localIndex]["options"]];
      const anotherTemp = data
        .slice(0, optionsIndex)
        .concat(data.slice(optionsIndex + 1));

      temp[field.type][field.localIndex] = {
        ...temp[field.type][field.localIndex],
        options: anotherTemp,
      };

      setAllValues(temp);
    } catch (error) {
      console.log(error, "error while trying to remove options field");
    }
  };

  const handleAddOptionsField = (field) => {
    try {
      let temp = { ...allValues };

      const newIndex = temp[field.type][field.localIndex]["options"].length + 1;

      let newOption = {
        id: newIndex,
        title: "",
      };
      let data = [...temp[field.type][field.localIndex]["options"], newOption];

      temp[field.type][field.localIndex] = {
        ...temp[field.type][field.localIndex],
        options: data,
      };

      setAllValues(temp);
    } catch (error) {
      console.log(error, "error while trying to add options field");
    }
  };

  const inputTypes = [
    { value: "number", label: "Number" },
    { value: "text", label: "Text" },
    { value: "textArea", label: "Text Area" },
    { value: "date", label: "Date" },
  ];
  useEffect(() => {
    console.log(allValues, "All values inside useEffect");
    console.log(errors, "errors", values);
    setValues(allValues);
  }, [allValues, errors]);

  function generateUniqueId() {
    const alphanumericCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueKey = "";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      uniqueKey += alphanumericCharacters.charAt(randomIndex);
    }

    return uniqueKey;
  }

  const handleMediaUpload = async (file, fieldType, localIndex) => {
    try {
      setIsLoading(true);
      console.log(localIndex, "Asdasdasdasd");
      console.log("Starting media upload process...");

      // Identify file type first
      setIsChildLoading(true);
      console.log("Setting setIsChildLoading(true)");

      if (!file) {
        // No file selected
        console.log("No file selected");
        return;
      }

      // Validate file size
      const maxSize = 15 * 1024 * 1024; // 15MB
      console.log(`File size: ${file.size} bytes`);
      if (file.size > maxSize) {
        console.log(
          "File size exceeds 15 MB limit. Please choose a smaller file."
        );
        error("File size exceeds 15 MB limit. Please choose a smaller file.");
        return;
      }

      const fileName = file.name;
      let isImage = false;
      let isVideo = false;
      let isPdf = false;

      if (file.type.includes("image")) {
        // Handle image file
        console.log("Handling image file...");
        const allowedTypes = [
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg+xml",
        ];
        if (!allowedTypes.includes(file.type)) {
          console.log("Invalid image file type");
          error(
            "Invalid file type. Please select a .png, .jpg, .jpeg, .svg file."
          );
          return;
        }

        isImage = true;
      } else if (file.type === "application/pdf") {
        // Handle PDF file
        console.log("Handling PDF file...");
        isPdf = true;
      } else if (file.type.includes("video")) {
        // Handle video file
        console.log("Handling video file...");
        const allowedVideoTypes = [
          "video/mp4",
          "video/mpeg",
          "video/webm",
          "video/quicktime",
          "video/x-msvideo",
        ];

        if (!allowedVideoTypes.includes(file.type)) {
          console.log("Invalid video file type");
          error(
            "Invalid file type. Please select a .mp4, .mpeg, .webm, .mov, .avi video file."
          );
          return;
        }

        isVideo = true;
      } else {
        console.log("Invalid file type");
        error(
          "Invalid file type. Please select an image (png, jpg, jpeg, svg) or a PDF file."
        );
        return;
      }

      const reader = new FileReader();

      reader.onloadend = async () => {
        console.log("File reader onloadend triggered");

        const formData = new FormData();
        formData.append("file", file);
        formData.append("company_id", companyId);

        console.log("Sending formData to getImageUrl...");

        const fileUrlRes = await getImageUrl(formData);

        if (fileUrlRes?.status === "success") {
          console.log("Received fileUrlRes from getImageUrl", fileUrlRes);

          let temp = { ...allValues };
          temp[fieldType][localIndex] = {
            ...temp[fieldType][localIndex],
            fileUrl: fileUrlRes.url,
            fileName: fileName,
            isImage: isImage,
            isPdf: isPdf,
            isVideo: isVideo,
          };
          setAllValues(temp);
          setValues(temp);
          onTextChange(props?.id, temp);

          console.log("Updated allValues state", temp);

          setIsLoading(false);

          setIsChildLoading(false);
          console.log("Finished media upload process");
        }
      };

      // Start reading the file as data URL
      reader.readAsDataURL(file);
      console.log("Started reading file as data URL");
    } catch (error) {
      console.error("Error while trying to upload media file:", error);
      console.log(error, "Error while trying to upload media file");
      setIsChildLoading(false);
    } finally {
      setIsLoading(true);
      // setLoadingState((prevLoadingState) => {
      //   prevLoadingState.filter((x) => x !== localIndex);
      //   return [...prevLoadingState];
      // });
    }
  };

  const Error = ({ fieldName, localIndex, property }) => {
    let field = `[${fieldName}][${localIndex}].${property}`;

    // Ensure errors is defined and field is valid
    if (!errors || !field) {
      console.log("errors or field is undefined");
      return null;
    }

    // console.log("Trying to access:", field);
    // console.log("Errors object:", errors[fieldName] ,localIndex, property,'property');
    if (
      errors[fieldName] &&
      errors[fieldName][localIndex] &&
      errors[fieldName][localIndex][property]
    ) {
      return (
        <aside style={{ marginTop: "5px" }} className="error-message-row">
          <span className="error-msg">
            {errors[fieldName][localIndex][property]}
          </span>
        </aside>
      );
    } else {
      return null;
    }
  };

  const ButtonError = ({
    fieldName,
    localIndex,
    flag,
    anotherIndex,
    property,
    oneMoreIndex,
  }) => {
    if (flag == true) {
      if (
        errors[fieldName] &&
        errors[fieldName][localIndex] &&
        errors[fieldName][localIndex]["buttons"] &&
        errors[fieldName][localIndex]["buttons"][anotherIndex]
      ) {
        return (
          <aside className="error-message-row">
            <span className="error-msg">
              {errors[fieldName][localIndex]["buttons"][anotherIndex]["title"]}
              {/* {console.log(errors[fieldName][localIndex]["buttons"][anotherIndex]["title"])} */}
            </span>
          </aside>
        );
      } else {
        return null;
      }
    }
    if (flag == false) {
      if (
        errors[fieldName] &&
        errors[fieldName][localIndex] &&
        errors[fieldName][localIndex]["sections"] &&
        errors[fieldName][localIndex]["sections"][anotherIndex]
      ) {
        if (property == "sectionTitle") {
          return (
            <aside className="error-message-row">
              <span className="error-msg">
                {
                  errors[fieldName][localIndex]["sections"][anotherIndex][
                    "sectionTitle"
                  ]
                }
                {/* {console.log(errors[fieldName][localIndex]["buttons"][anotherIndex]["title"])} */}
              </span>
            </aside>
          );
        } else if (property == "itemTitle") {
          if (
            errors[fieldName] &&
            errors[fieldName][localIndex] &&
            errors[fieldName][localIndex]["sections"] &&
            errors[fieldName][localIndex]["sections"][anotherIndex] &&
            errors[fieldName][localIndex]["sections"][anotherIndex]["items"] &&
            errors[fieldName][localIndex]["sections"][anotherIndex]["items"][
              oneMoreIndex
            ]
          ) {
            return (
              <aside className="error-message-row">
                <span className="error-msg">
                  {
                    errors[fieldName][localIndex]["sections"][anotherIndex][
                      "items"
                    ][oneMoreIndex]["itemTitle"]
                  }
                  {/* {console.log(errors[fieldName][localIndex]["buttons"][anotherIndex]["title"])} */}
                </span>
              </aside>
            );
          }
        }
      }
    }
    return null;
  };

  const handleValueChangeCommon = (type, localIndex, field, value, flag) => {
    if (flag === true) {
      let maxLength = 0;

      if (field === "body" || field == "value") {
        maxLength = 1024;
      } else if (field === "header" || field === "footer") {
        maxLength = 60;
      } else if (field === "listTitle") {
        maxLength = 20;
      }

      // Trim value if it exceeds maxLength
      if (value && value.length > maxLength) {
        value = value.slice(0, maxLength);
        error(`Maximum ${maxLength} characters are allowed in ${field}`);
      }

      const temp = { ...allValues };
      temp[type][localIndex] = {
        ...temp[type][localIndex],
        [field]: value,
      };
      setAllValues(temp);
      setValues(temp);
      onTextChange(props?.id, temp);
    } else if (flag == false) {
      let maxLength = 20;

      if (value && value.length > maxLength) {
        value = value.slice(0, maxLength);
        error(`Maximum ${maxLength} characters are allowed in Button Title`);
      }
      const temp = { ...allValues };

      //here consider field as buttonIndex
      temp[type][localIndex].buttons[field] = {
        ...temp[type][localIndex].buttons[field],
        title: value,
      };
      setAllValues(temp);
      setValues(temp);
      onTextChange(props?.id, temp);
    }
  };

  return (
    <>
      <div className="text-updater-node flow-comn-box" onClick={() => {}}>
        {/* <div
          className="flow-comn-close-btn"
          onClick={handleDeleteParentNode}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-times-circle"></i>
        </div> */}
        <div className="flow-title-box">
          <aside>Message</aside>
          {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
          <div onClick={handleDeleteParentNode} className="preview-eye-button">
            <i className="fas fa-trash"></i>
          </div>
        </div>
        {/* ---------------------------------Screen Content-------------------------------------------- */}
        {allValues.indexing.length > 0 &&
          allValues.indexing.map((field, indexOfField) => {
            return (
              <div key={indexOfField}>
                {/* New content types */}
                {field.type === "textButtons" && (
                  <>
                    {indexOfField == 0 ? (
                      <></>
                    ) : (
                      <>
                        <div style={{ height: "10px" }}></div>
                      </>
                    )}{" "}
                    <div
                      style={{ position: "relative" }}
                      className="flow-inner-row flow-textarea-row"
                    >
                      {/* <div className="flow-header mb-1"> */}
                      {/* <span>Text Buttons {field.localIndex + 1}</span> */}
                      <div
                        onClick={() => {
                          // const temp = Object.assign({}, allValues);

                          // // let temp = { ...allValues };
                          // // temp[field.type][field.localIndex].buttons.splice(
                          // //   buttonIndex,
                          // //   1
                          // // );

                          // console.log(allValues, "allValues before removing");
                          // console.log(temp, "temp before removing");

                          // temp.indexing.splice(indexOfField, 1);
                          // temp[field.type].splice(field.localIndex, 1);
                          // console.log(allValues, "allValues after removing");
                          // console.log(temp, "temp after removing");

                          // setAllValues(temp);

                          const section = allValues.indexing[indexOfField];
                          const { type, localIndex } = section;

                          const temp = { ...allValues };

                          // Remove the section from the specific array
                          temp[type].splice(localIndex, 1);

                          // Remove the corresponding entry from indexing
                          temp.indexing.splice(indexOfField, 1);

                          // Update the local indices in indexing array
                          temp.indexing = temp.indexing.map((item, i) => {
                            if (
                              item.type === type &&
                              item.localIndex > localIndex
                            ) {
                              return {
                                ...item,
                                localIndex: item.localIndex - 1,
                              };
                            }
                            return item;
                          });

                          setAllValues(temp);
                          setValues(temp);
                          onTextChange(props?.id, temp);
                        }}
                        style={{ cursor: "pointer" }}
                        className="flow-comn-close-btn"
                      >
                        <i className="fas fa-times-circle"></i>
                      </div>
                      {/* </div> */}
                      {/* <label htmlFor={`indexing=${indexOfField}-header`}>
                          Header
                        </label> */}

                      {["header", "body", "footer"].map((fieldMap) => {
                        return (
                          <>
                            <aside>
                              <TextField
                                className="textfield-padding-small w-100"
                                id={`indexing-${indexOfField}-${fieldMap}`}
                                size="large"
                                multiline
                                placeholder={
                                  fieldMap == "body"
                                    ? "Body"
                                    : `${fieldMap}(Optional)`
                                }
                                minRows={fieldMap == "body" ? 3 : 1}
                                value={
                                  allValues[field.type][field.localIndex][
                                    fieldMap
                                  ] || ""
                                }
                                onChange={(e) => {
                                  handleValueChangeCommon(
                                    field.type,
                                    field.localIndex,
                                    fieldMap,
                                    e.target.value,
                                    true
                                  );
                                }}
                              />
                              {fieldMap == "body" ? (
                                <Error
                                  fieldName={"textButtons"}
                                  localIndex={field.localIndex}
                                  property={"body"}
                                />
                              ) : (
                                <></>
                              )}

                              <div className="counter-class">
                                {allValues[field.type][field.localIndex][
                                  fieldMap
                                ]?.length !== 0
                                  ? `${
                                      allValues[field.type][field.localIndex][
                                        fieldMap
                                      ]?.length
                                    }/${
                                      fieldMap === "body"
                                        ? 1024
                                        : fieldMap === "header" ||
                                          fieldMap === "footer"
                                        ? 60
                                        : ""
                                    }`
                                  : null}
                              </div>
                            </aside>
                          </>
                        );
                      })}

                      {allValues[field.type][field.localIndex]?.buttons?.map(
                        (button, buttonIndex) => (
                          <div
                            key={buttonIndex}
                            style={{
                              marginTop: "8px",
                              // marginBottom: "5px",
                              position: "relative",
                            }}
                          >
                            <TextField
                              label={`Button Title ${buttonIndex + 1}`}
                              className="w-100 text-btn-add-row"
                              id={`indexing-${indexOfField}-button-${buttonIndex}`}
                              multiline
                              minRows={2}
                              // placeholder="Enter button text"
                              value={button.title}
                              onChange={(e) => {
                                // let temp = { ...allValues };
                                // temp[field.type][field.localIndex].buttons[
                                //   buttonIndex
                                // ].title = e.target.value;
                                // setAllValues(temp);
                                handleValueChangeCommon(
                                  field.type,
                                  field.localIndex,
                                  buttonIndex,
                                  e.target.value,
                                  false
                                );
                              }}
                            />
                            <ButtonError
                              fieldName={field.type}
                              localIndex={field.localIndex}
                              flag={true}
                              anotherIndex={buttonIndex}
                            />
                            <p
                              className="flow-comn-close-btn"
                              onClick={() => {
                                let temp = { ...allValues };
                                temp[field.type][
                                  field.localIndex
                                ].buttons.splice(buttonIndex, 1);
                                setAllValues(temp);
                                setValues(temp);
                                onTextChange(props?.id, temp);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fas fa-times-circle"></i>
                            </p>
                            <p>
                              <Handle
                                id={`${props?.id}_${field.type}_${field.localIndex}_${buttonIndex}`}
                                type="source"
                                className="flow-trigger"
                              ></Handle>
                            </p>{" "}
                            <div
                              style={{ marginTop: "10px" }}
                              className="counter-class"
                            >
                              {button?.title?.length !== 0
                                ? `${button?.title?.length}/20`
                                : null}
                            </div>
                          </div>
                        )
                      )}
                      {allValues[field.type][field.localIndex]?.buttons
                        ?.length < 3 && (
                        <Button
                          className="flow-comn-btn mt-2"
                          onClick={() => {
                            let temp = { ...allValues };
                            temp[field.type][field.localIndex].buttons.push({
                              title: "",
                              uniqueId: generateUniqueId(),
                            });
                            setAllValues(temp);
                            setValues(temp);
                            onTextChange(props?.id, temp);
                          }}
                        >
                          <i className="fas fa-plus mr-2"></i> Add Button
                        </Button>
                      )}
                    </div>
                  </>
                )}

                {field.type === "mediaButtons" && (
                  <>
                    {indexOfField == 0 ? (
                      <></>
                    ) : (
                      <>
                        <div style={{ height: "10px" }}></div>
                      </>
                    )}
                    <div
                      style={{ position: "relative" }}
                      className="flow-inner-row"
                    >
                      {/* <div className="flow-header">
                        <span>Media Buttons {field.localIndex + 1}</span> */}

                      <div
                        className="flow-comn-close-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const section = allValues.indexing[indexOfField];
                          const { type, localIndex } = section;

                          const temp = { ...allValues };

                          // Remove the section from the specific array
                          temp[type].splice(localIndex, 1);

                          // Remove the corresponding entry from indexing
                          temp.indexing.splice(indexOfField, 1);

                          // Update the local indices in indexing array
                          temp.indexing = temp.indexing.map((item, i) => {
                            if (
                              item.type === type &&
                              item.localIndex > localIndex
                            ) {
                              return {
                                ...item,
                                localIndex: item.localIndex - 1,
                              };
                            }
                            return item;
                          });

                          setAllValues(temp);
                          setValues(temp);
                          onTextChange(props?.id, temp);
                        }}
                      >
                        <i className="fas fa-times-circle"></i>
                      </div>
                      {/* </div> */}
                      <div className="flow-inner-box">
                        <div className="flow-file-input">
                          <i class="fas fa-image"></i>

                          <Input
                            // className="file-upload-name-input"
                            type="file"
                            //   accept="image/*, video/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              // let temp = { ...allValues };
                              // temp[field.type][field.localIndex] = {
                              //   ...temp[field.type][field.localIndex],
                              //   file: file,
                              // };
                              // setAllValues(temp);

                              // Call function to upload file and update URL
                              handleMediaUpload(
                                file,
                                field.type,
                                field.localIndex
                              );
                            }}
                          />
                          <div style={{ bottom: "-5px" }}>
                            <Error
                              fieldName={"mediaButtons"}
                              localIndex={field.localIndex}
                              property={"fileUrl"}
                            />
                          </div>
                        </div>
                        <span
                          style={{
                            display: "block",
                            backgroundColor: "#E2E2E2",
                            textTransform: "lowercase",
                            width: "100%",
                            margin: "10px 0px 0px",
                            padding: "5px 10px",
                            borderRadius: "3px",
                            paddingTop: "10px",
                          }}
                        >
                          {" "}
                          You can upload Image,Video, or Pdf here{" "}
                        </span>
                        {/* Display preview of uploaded media */}
                        {console.log(
                          loadingState,
                          "Asdasdasd",
                          field.localIndex
                        )}
                        {
                          <>
                            {allValues[field.type][field.localIndex]
                              ?.fileUrl !== "" && (
                              <div className="flow-image-preview-box">
                                {allValues[field.type][field.localIndex][
                                  "isPdf"
                                ] == true ? (
                                  <>
                                    <img src={defaultImageForPdf} />
                                  </>
                                ) : allValues[field.type][field.localIndex][
                                    "isVideo"
                                  ] == true ? (
                                  <>
                                    <>
                                      <img src={defaultImageForVideo} />
                                    </>
                                  </>
                                ) : allValues[field.type][field.localIndex][
                                    "isImage"
                                  ] == true ? (
                                  <>
                                    <img
                                      // src={URL.createObjectURL(
                                      //   allValues[field.type][field.localIndex].file
                                      // )}
                                      src={
                                        allValues[field.type][field.localIndex]
                                          .fileUrl
                                      }
                                      alt="Media Preview"
                                    />
                                  </>
                                ) : null}
                              </div>
                            )}
                          </>
                        }
                      </div>

                      {/* <label htmlFor={`indexing-${indexOfField}-body`}>
                          Body
                        </label> */}
                      <aside className="position-relative">
                        <TextField
                          className="w-100 textfield-padding-small"
                          size="large"
                          id={`indexing-${indexOfField}-body`}
                          multiline
                          placeholder="Enter body text"
                          value={
                            allValues[field.type][field.localIndex]?.body || ""
                          }
                          minRows={3}
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                            borderRadius: "4px",
                          }}
                          onChange={(e) => {
                            // let temp = { ...allValues };
                            // temp[field.type][field.localIndex] = {
                            //   ...temp[field.type][field.localIndex],
                            //   body: e.target.value,
                            // };
                            // setAllValues(temp);
                            handleValueChangeCommon(
                              field.type,
                              field.localIndex,
                              "body",
                              e.target.value,
                              true
                            );
                          }}
                        />
                        <Error
                          fieldName={"mediaButtons"}
                          localIndex={field.localIndex}
                          property={"body"}
                        />
                        <div
                          style={{ zIndex: "999" }}
                          className="counter-class"
                        >
                          {allValues[field.type][field.localIndex]?.body
                            ?.length !== 0
                            ? `${
                                allValues[field.type][field.localIndex]?.body
                                  ?.length
                              }/1024`
                            : null}
                        </div>
                      </aside>

                      {/* Buttons */}
                      {allValues[field.type][field.localIndex]?.buttons?.map(
                        (button, buttonIndex) => (
                          <div className="flow-textarea-row">
                            {/* <div
                              style={{ position: "relative" }}
                              key={buttonIndex}
                              className="text-btn-add-row"
                            >
                              <TextField
                                className="w-100 text-btn-add-row"
                                id={`indexing-${indexOfField}-button-${buttonIndex}`}
                                multiline
                                label={`Button title ${buttonIndex + 1}`}
                                value={button.title}
                                onChange={(e) => {
                                  let temp = { ...allValues };
                                  temp[field.type][field.localIndex].buttons[
                                    buttonIndex
                                  ].title = e.target.value;
                                  setAllValues(temp);
                                }}
                              />

                              <ButtonError
                                fieldName={field.type}
                                localIndex={field.localIndex}
                                flag={true}
                                anotherIndex={buttonIndex}
                              />
                          
                              <p
                                className="flow-comn-close-btn"
                                onClick={() => {
                                  let temp = { ...allValues };
                                  temp[field.type][
                                    field.localIndex
                                  ].buttons.splice(buttonIndex, 1);
                                  setAllValues(temp);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fas fa-times-circle"></i>
                              </p>
                              <p>
                                <Handle
                                  id={`${props?.id}_${field.type}_${field.localIndex}_${buttonIndex}`}
                                  type="source"
                                  className="flow-trigger"
                                ></Handle>
                              </p>
                            </div> */}
                            <div
                              key={buttonIndex}
                              style={{
                                marginTop: "4px",
                                // marginBottom: "5px",
                                position: "relative",
                              }}
                            >
                              <TextField
                                label={`Button Title ${buttonIndex + 1}`}
                                className="w-100 text-btn-add-row"
                                id={`indexing-${indexOfField}-button-${buttonIndex}`}
                                multiline
                                minRows={2}
                                // placeholder="Enter button text"
                                value={button.title}
                                onChange={(e) => {
                                  // let temp = { ...allValues };
                                  // temp[field.type][field.localIndex].buttons[
                                  //   buttonIndex
                                  // ].title = e.target.value;
                                  // setAllValues(temp);
                                  handleValueChangeCommon(
                                    field.type,
                                    field.localIndex,
                                    buttonIndex,
                                    e.target.value,
                                    false
                                  );
                                }}
                              />
                              <ButtonError
                                fieldName={field.type}
                                localIndex={field.localIndex}
                                flag={true}
                                anotherIndex={buttonIndex}
                              />
                              <p
                                className="flow-comn-close-btn"
                                onClick={() => {
                                  let temp = { ...allValues };
                                  temp[field.type][
                                    field.localIndex
                                  ].buttons.splice(buttonIndex, 1);
                                  setAllValues(temp);
                                  setValues(temp);
                                  onTextChange(props?.id, temp);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fas fa-times-circle"></i>
                              </p>
                              <p>
                                <Handle
                                  id={`${props?.id}_${field.type}_${field.localIndex}_${buttonIndex}`}
                                  type="source"
                                  className="flow-trigger"
                                ></Handle>
                              </p>{" "}
                              <div
                                style={{ marginTop: "10px" }}
                                className="counter-class"
                              >
                                {button?.title?.length !== 0
                                  ? `${button?.title?.length}/20`
                                  : null}
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      {/* Add Button */}
                      {allValues[field.type][field.localIndex]?.buttons
                        ?.length < 3 && (
                        <Button
                          className="flow-comn-btn"
                          onClick={() => {
                            let temp = { ...allValues };
                            temp[field.type][field.localIndex].buttons.push({
                              title: "",
                              uniqueId: generateUniqueId(),
                            });
                            setAllValues(temp);
                            setValues(temp);
                            onTextChange(props?.id, temp);
                          }}
                        >
                          Add Button
                        </Button>
                      )}
                    </div>
                  </>
                )}

                {field.type === "textLists" && (
                  <>
                    {indexOfField == 0 ? (
                      <></>
                    ) : (
                      <>
                        <div style={{ height: "10px" }}></div>
                      </>
                    )}
                    <div
                      style={{ position: "relative" }}
                      className="flow-inner-row flow-textarea-row"
                    >
                      {/* <div className="flow-header mb-1">
                        <span>Text List {field.localIndex + 1}</span> */}

                      <div
                        className="flow-comn-close-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const section = allValues.indexing[indexOfField];
                          const { type, localIndex } = section;

                          const temp = { ...allValues };

                          // Remove the section from the specific array
                          temp[type].splice(localIndex, 1);

                          // Remove the corresponding entry from indexing
                          temp.indexing.splice(indexOfField, 1);

                          // Update the local indices in indexing array
                          temp.indexing = temp.indexing.map((item, i) => {
                            if (
                              item.type === type &&
                              item.localIndex > localIndex
                            ) {
                              return {
                                ...item,
                                localIndex: item.localIndex - 1,
                              };
                            }
                            return item;
                          });

                          setAllValues(temp);
                          setValues(temp);
                          onTextChange(props?.id, temp);
                        }}
                      >
                        <i className="fas fa-times-circle"></i>
                      </div>
                      {/* </div> */}
                      {/* <div
                        onClick={() => {
                          // const temp = Object.assign({}, allValues);

                          // // let temp = { ...allValues };
                          // // temp[field.type][field.localIndex].buttons.splice(
                          // //   buttonIndex,
                          // //   1
                          // // );

                          // console.log(allValues, "allValues before removing");
                          // console.log(temp, "temp before removing");

                          // temp.indexing.splice(indexOfField, 1);
                          // temp[field.type].splice(field.localIndex, 1);
                          // console.log(allValues, "allValues after removing");
                          // console.log(temp, "temp after removing");

                          // setAllValues(temp);

                          const section = allValues.indexing[indexOfField];
                          const { type, localIndex } = section;

                          const temp = { ...allValues };

                          // Remove the section from the specific array
                          temp[type].splice(localIndex, 1);

                          // Remove the corresponding entry from indexing
                          temp.indexing.splice(indexOfField, 1);

                          // Update the local indices in indexing array
                          temp.indexing = temp.indexing.map((item, i) => {
                            if (
                              item.type === type &&
                              item.localIndex > localIndex
                            ) {
                              return {
                                ...item,
                                localIndex: item.localIndex - 1,
                              };
                            }
                            return item;
                          });

                          setAllValues(temp);
                          setValues(temp);
                          onTextChange(props?.id, temp);
                        }}
                        style={{ cursor: "pointer" }}
                        className="flow-comn-close-btn"
                      >
                        <i className="fas fa-times-circle"></i>
                      </div> */}

                      {["header", "body", "footer", "listTitle"].map(
                        (fieldMap) => {
                          return (
                            <>
                              <aside>
                                <TextField
                                  size="large"
                                  className="w-100 textfield-padding-small"
                                  id={`indexing-${indexOfField}-${fieldMap}`}
                                  multiline
                                  minRows={
                                    fieldMap == "body"
                                      ? 3
                                      : fieldMap == "listTitle"
                                      ? 1.5
                                      : 1
                                  }
                                  placeholder={
                                    fieldMap == "body"
                                      ? "Body"
                                      : fieldMap == "listTitle"
                                      ? "List Title"
                                      : `${fieldMap}(Optional)`
                                  }
                                  value={
                                    allValues[field.type][field.localIndex][
                                      fieldMap
                                    ] || ""
                                  }
                                  onChange={(e) => {
                                    handleValueChangeCommon(
                                      field.type,
                                      field.localIndex,
                                      fieldMap,
                                      e.target.value,
                                      true
                                    );
                                  }}
                                />
                                {fieldMap == "body" ? (
                                  <Error
                                    fieldName={"textLists"}
                                    localIndex={field.localIndex}
                                    property={"body"}
                                  />
                                ) : fieldMap == "listTitle" ? (
                                  <>
                                    <Error
                                      fieldName={"textLists"}
                                      localIndex={field.localIndex}
                                      property={"listTitle"}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}

                                <div className="counter-class">
                                  {allValues[field.type][field.localIndex][
                                    fieldMap
                                  ]?.length !== 0
                                    ? `${
                                        allValues[field.type][field.localIndex][
                                          fieldMap
                                        ]?.length
                                      }/${
                                        fieldMap === "body"
                                          ? 1024
                                          : fieldMap === "header" ||
                                            fieldMap === "footer"
                                          ? 60
                                          : fieldMap == "listTitle"
                                          ? 20
                                          : ""
                                      }`
                                    : null}
                                </div>
                              </aside>
                            </>
                          );
                        }
                      )}

                      {/* <TextField
                        size="large"
                        className="w-100 textfield-padding-small"
                        id={`indexing-${indexOfField}-header`}
                        multiline
                        minRows={1}
                        placeholder="Header (Optional)"
                        value={
                          allValues[field.type][field.localIndex]?.header || ""
                        }
                        onChange={(e) => {
                          let temp = { ...allValues };
                          temp[field.type][field.localIndex] = {
                            ...temp[field.type][field.localIndex],
                            header: e.target.value,
                          };
                          setAllValues(temp);
                        }}
                      />
                      <aside>
                        <TextField
                          className="w-100 textfield-padding-small"
                          id={`indexing-${indexOfField}-body`}
                          multiline
                          placeholder="Body"
                          minRows={3}
                          value={
                            allValues[field.type][field.localIndex]?.body || ""
                          }
                          onChange={(e) => {
                            let temp = { ...allValues };
                            temp[field.type][field.localIndex] = {
                              ...temp[field.type][field.localIndex],
                              body: e.target.value,
                            };
                            setAllValues(temp);
                          }}
                        />
                        <Error
                          fieldName={field.type}
                          localIndex={field.localIndex}
                          property={"body"}
                        />
                      </aside>
                      <TextField
                        className="w-100 textfield-padding-small"
                        id={`indexing-${indexOfField}-footer`}
                        multiline
                        placeholder="Footer(Optional)"
                        minRows={1}
                        value={
                          allValues[field.type][field.localIndex]?.footer || ""
                        }
                        onChange={(e) => {
                          let temp = { ...allValues };
                          temp[field.type][field.localIndex] = {
                            ...temp[field.type][field.localIndex],
                            footer: e.target.value,
                          };
                          setAllValues(temp);
                        }}
                      />
                      <aside>
                        <TextField
                          className="w-100 textfield-padding-small"
                          id={`indexing-${indexOfField}-listTitle`}
                          multiline
                          placeholder="List Title"
                          minRows={1.5}
                          value={
                            allValues[field.type][field.localIndex]
                              ?.listTitle || ""
                          }
                          onChange={(e) => {
                            let temp = { ...allValues };
                            temp[field.type][field.localIndex] = {
                              ...temp[field.type][field.localIndex],
                              listTitle: e.target.value,
                            };
                            setAllValues(temp);
                          }}
                        />

                        <Error
                          fieldName={field.type}
                          localIndex={field.localIndex}
                          property={"listTitle"}
                        />
                      </aside> */}
                      {allValues[field.type][field.localIndex]?.sections?.map(
                        (section, sectionIndex) => (
                          <div
                            className="text-btn-add-row mt-2"
                            style={{ position: "relative" }}
                            key={sectionIndex}
                          >
                            {/* <Button
                              onClick={() => {
                                let temp = { ...allValues };
                                let removed = temp[field.type][
                                  field.localIndex
                                ].sections.splice(sectionIndex, 1);
                                setAllValues(temp);
                              }}
                            >
                              Remove Section 3
                            </Button> */}
                            <div className="position-relative">
                              <TextField
                                className="w-100"
                                id={`indexing-${indexOfField}-section-${sectionIndex}-sectionTitle`}
                                multiline
                                placeholder="Enter section title"
                                value={section.sectionTitle}
                                onChange={(e) => {
                                  let temp = { ...allValues };
                                  let value = e.target.value;
                                  if (value?.length > 20) {
                                    value = value.slice(0, 20);
                                    error(
                                      "Max 20 characters are allowed in Section Title"
                                    );
                                  }
                                  temp[field.type][field.localIndex].sections[
                                    sectionIndex
                                  ].sectionTitle = value;
                                  setAllValues(temp);
                                  setValues(temp);
                                  onTextChange(props?.id, temp);
                                }}
                              />

                              <ButtonError
                                fieldName={field.type}
                                localIndex={field.localIndex}
                                flag={false}
                                anotherIndex={sectionIndex}
                                property={"sectionTitle"}
                              />

                              <div className="counter-class">
                                {section?.sectionTitle?.length > 0 ? (
                                  <>{`${section?.sectionTitle?.length}/20`}</>
                                ) : null}
                              </div>
                              <p
                                className="flow-comn-close-btn"
                                onClick={() => {
                                  let temp = { ...allValues };
                                  temp[field.type][
                                    field.localIndex
                                  ].sections.splice(sectionIndex, 1);
                                  setAllValues(temp);
                                  setValues(temp);
                                  onTextChange(props?.id, temp);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fas fa-times-circle"></i>
                              </p>
                            </div>
                            {section?.items?.length > 0 ? (
                              <>
                                {" "}
                                {section?.items?.map((item, ind) => {
                                  return (
                                    <>
                                      <div
                                        style={{
                                          position: "relative",
                                          backgroundColor: "transparent",
                                          padding: 0,
                                        }}
                                      >
                                        {section?.items?.length !== 1 ? (
                                          <>
                                            {" "}
                                            <p
                                              style={{ cursor: "pointer" }}
                                              className="flow-comn-close-btn"
                                              onClick={() => {
                                                const temp = { ...allValues };

                                                temp[field.type][
                                                  field.localIndex
                                                ].sections[sectionIndex][
                                                  "items"
                                                ].splice(ind, 1);

                                                setAllValues(temp);
                                                setValues(temp);
                                                onTextChange(props?.id, temp);
                                              }}
                                            >
                                              <i className="fas fa-times-circle"></i>
                                            </p>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        <div
                                          className="mt-2"
                                          style={{
                                            position: "relative",
                                            padding: "10px 8px",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          {console.log(
                                            allValues,
                                            "All values list"
                                          )}
                                          <TextField
                                            size="large"
                                            className="w-100 textfield-padding-small"
                                            id={`indexing-${indexOfField}-section-${sectionIndex}-itemTitle`}
                                            multiline
                                            placeholder="Item Title"
                                            value={section.items[ind].itemTitle}
                                            onChange={(e) => {
                                              let temp = Object.assign(
                                                {},
                                                allValues
                                              );
                                              // let temp = { ...allValues };
                                              let value = e.target.value;
                                              if (value?.length > 20) {
                                                value = value.slice(0, 20);
                                                error(
                                                  "Maximum 20 characters are allowed in Item Title"
                                                );
                                              }
                                              temp[field.type][
                                                field.localIndex
                                              ].sections[sectionIndex]["items"][
                                                ind
                                              ].itemTitle = value;

                                              setAllValues(temp);
                                              // setValues(temp);
                                              // onTextChange(props?.id, temp);
                                            }}
                                          />

                                          <ButtonError
                                            fieldName={field.type}
                                            localIndex={field.localIndex}
                                            flag={false}
                                            anotherIndex={sectionIndex}
                                            oneMoreIndex={ind}
                                            property={"itemTitle"}
                                          />

                                          <div className="counter-class">
                                            {section.items[ind].itemTitle
                                              ?.length > 0 ? (
                                              <>
                                                {`${section.items[ind].itemTitle?.length}/20`}
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div
                                          className="mt-2"
                                          style={{
                                            position: "relative",
                                            padding: "10px 8px",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          <TextField
                                            size="large"
                                            minRows={2}
                                            className="w-100 textfield-padding-small mt-2"
                                            id={`indexing-${indexOfField}-section-${sectionIndex}-itemDescription`}
                                            multiline
                                            placeholder="Item Description"
                                            value={
                                              section.items[ind].itemDescription
                                            }
                                            onChange={(e) => {
                                              let temp = { ...allValues };
                                              let value = e.target.value;
                                              if (value?.length > 72) {
                                                value = value.slice(0, 72);
                                                error(
                                                  "Maximum 72 characters are allowed in Item Description"
                                                );
                                              }
                                              temp[field.type][
                                                field.localIndex
                                              ].sections[sectionIndex]["items"][
                                                ind
                                              ].itemDescription = value;
                                              setAllValues(temp);
                                              setValues(temp);
                                              onTextChange(props?.id, temp);
                                            }}
                                          />
                                          <Handle
                                            id={`${props?.id}_${field.type}_${field.localIndex}_${sectionIndex}_${ind}`}
                                            type="source"
                                            className="flow-trigger"
                                          ></Handle>

                                          <div className="counter-class">
                                            {section.items[ind].itemDescription
                                              ?.length > 0 ? (
                                              <>
                                                {`${section.items[ind].itemDescription?.length}/72`}
                                              </>
                                            ) : null}
                                          </div>
                                        </div>

                                        {ind == section?.items?.length - 1 ||
                                        section?.items?.length == 0 ? (
                                          <>
                                            {" "}
                                            <Button
                                              className="flow-comn-btn"
                                              onClick={() => {
                                                let temp = { ...allValues };
                                                temp[field.type][
                                                  field.localIndex
                                                ].sections[sectionIndex][
                                                  "items"
                                                ].push({
                                                  uniqueId: generateUniqueId(),
                                                  itemTitle: "",
                                                  itemDescription: "",
                                                });

                                                setAllValues(temp);
                                                setValues(temp);
                                                onTextChange(props?.id, temp);
                                              }}
                                            >
                                              Add Item
                                            </Button>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>{" "}
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      )}

                      <Button
                        className="flow-comn-btn"
                        onClick={() => {
                          let temp = { ...allValues };
                          temp[field.type][field.localIndex].sections.push({
                            sectionTitle: "",
                            items: [
                              {
                                itemTitle: "",
                                itemDescription: "",
                                uniqueId: generateUniqueId(),
                              },
                            ],
                          });
                          setAllValues(temp);
                          setValues(temp);
                          onTextChange(props?.id, temp);
                        }}
                      >
                        Add Section
                      </Button>
                    </div>
                  </>
                )}

                {field.type === "texts" && (
                  <>
                    {indexOfField == 0 ? (
                      <></>
                    ) : (
                      <>
                        <div style={{ height: "10px" }}></div>
                      </>
                    )}

                    <div
                      style={{ position: "relative" }}
                      className="flow-inner-row flow-textarea-row"
                    >
                      {/* <div className="flow-header mb-1">
                        <span>Text {field.localIndex + 1}</span> */}

                      <div
                        className="flow-comn-close-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const section = allValues.indexing[indexOfField];
                          const { type, localIndex } = section;

                          const temp = { ...allValues };

                          // Remove the section from the specific array
                          temp[type].splice(localIndex, 1);

                          // Remove the corresponding entry from indexing
                          temp.indexing.splice(indexOfField, 1);

                          // Update the local indices in indexing array
                          temp.indexing = temp.indexing.map((item, i) => {
                            if (
                              item.type === type &&
                              item.localIndex > localIndex
                            ) {
                              return {
                                ...item,
                                localIndex: item.localIndex - 1,
                              };
                            }
                            return item;
                          });

                          setAllValues(temp);
                          setValues(temp);
                          onTextChange(props?.id, temp);
                        }}
                      >
                        <i className="fas fa-times-circle"></i>
                      </div>
                      {/* </div> */}
                      <div
                        onClick={() => {
                          const section = allValues.indexing[indexOfField];
                          const { type, localIndex } = section;

                          const temp = { ...allValues };

                          // Remove the section from the specific array
                          temp[type].splice(localIndex, 1);

                          // Remove the corresponding entry from indexing
                          temp.indexing.splice(indexOfField, 1);

                          // Update the local indices in indexing array
                          temp.indexing = temp.indexing.map((item, i) => {
                            if (
                              item.type === type &&
                              item.localIndex > localIndex
                            ) {
                              return {
                                ...item,
                                localIndex: item.localIndex - 1,
                              };
                            }
                            return item;
                          });

                          setAllValues(temp);
                          setValues(temp);
                          onTextChange(props?.id, temp);
                        }}
                        style={{ cursor: "pointer" }}
                        className="flow-comn-close-btn"
                      >
                        {/* <i className="fas fa-times-circle"></i> */}
                      </div>

                      <div style={{ position: "relative" }}>
                        <TextField
                          size="large"
                          className="w-100 textfield-padding-small"
                          id={`indexing-${indexOfField}-texts`}
                          multiline
                          minRows={3}
                          placeholder="Enter Text Here"
                          value={
                            allValues[field.type][field.localIndex]["value"] ||
                            ""
                          }
                          onChange={(e) => {
                            handleValueChangeCommon(
                              field.type,
                              field.localIndex,
                              "value",
                              e.target.value,
                              true
                            );
                          }}
                        />
                        <Error
                          fieldName={"texts"}
                          localIndex={field.localIndex}
                          property={"value"}
                        />

                        <div className="counter-class">
                          {allValues[field.type][field.localIndex]["value"]
                            ?.length !== 0
                            ? `${
                                allValues[field.type][field.localIndex]["value"]
                                  ?.length
                              }/${1024}`
                            : null}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        <div className="Addition-Div mt-2">
          {/* New content type buttons */}
          {/* <Button id="basic-button6" onClick={() => {}}>
            Text + Buttons
          </Button>
          <Button id="basic-button7" onClick={() => {}}>
            Media + Buttons
          </Button>
          <Button id="basic-button8" onClick={() => {}}>
            Text + List
          </Button> */}

          <button
            style={{ width: "100%" }}
            className="comn-btn"
            variant="contained"
            id="demo-positioned-button"
            onClick={handleMenuClick}
          >
            + Add Content
          </button>
          <Menu
            className="content-dropdown"
            id="demo-positioned-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                handleAddContent("textButtons", {
                  index: allValues?.textButtons?.length + 1,
                  header: "",
                  body: "",
                  footer: "",
                  buttons: [],
                });
                handleMenuClose();
              }}
            >
              {" "}
              <i
                style={{ color: "#489987", marginRight: "5px" }}
                className="fas fa-text-width"
              ></i>
              Text + Buttons
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAddContent("mediaButtons", {
                  index: allValues?.mediaButtons?.length + 1,
                  file: null,
                  fileUrl: "",
                  fileName: "",
                  isImage: false,
                  isVideo: false,
                  isPdf: false,
                  body: "",
                  buttons: [],
                });
                handleMenuClose();
              }}
            >
              {" "}
              <i
                style={{ color: "#489987", marginRight: "5px" }}
                className="fas fa-images"
              ></i>
              Media + Buttons
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAddContent("textLists", {
                  index: allValues?.textLists?.length + 1,
                  header: "",
                  footer: "",
                  listTitle: "",
                  body: "",
                  sections: [],
                });
                handleMenuClose();
              }}
            >
              {" "}
              <i
                style={{ color: "#489987", marginRight: "5px" }}
                className="fas fa-list"
              ></i>
              Text + List
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAddContent("texts", {
                  index: allValues?.texts?.length + 1,
                  value: "",
                });
                handleMenuClose();
              }}
            >
              {" "}
              <i
                style={{ color: "#489987", marginRight: "5px" }}
                className="fas fa-list"
              ></i>
              Text
            </MenuItem>
          </Menu>
        </div>

        {isConnecting ? (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger "
              style={{
                overflow: "hidden",
                width: "100%", // Adjust the width as needed
                height: "100%",
                // height: "110%",
                background: isConnecting ? "transparent" : "transparent",
                border: "none",
                position: "absolute",
                left: "0px", // Adjust position to the left side of the node
                top: "50%",
                zIndex: 9,
                borderRadius: 0,
                cursor: isConnecting ? "default" : "move", // Change cursor based on connection state
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger flow-trigger-fill"
              style={{
                left: "1px",
                right: "auto",
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        )}
      </div>
    </>
  );
};

export default Enhancer(NewCommonNode);
