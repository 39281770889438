import { api, handleResponse, handleError } from "./apiServices";

export const saveFlow = (data) =>
  api()
    .post("/api/wpbox/storeFlows", data)
    .then(handleResponse)
    .catch(handleError);

export const checkToken = (data) =>
  api()
    .post(`api/wpbox/checkToken`, data)
    .then(handleResponse)
    .catch(handleError);

export const getEditData = (data) =>
  api()
    .post(`api/wpbox/editFlows`, data)
    .then(handleResponse)
    .catch(handleError);

export const getTemplateData = (companyId) =>
  api()
    .get(`api/wpbox/getAllTemplates?company_id=${companyId}`)
    .then(handleResponse)
    .catch(handleError);

export const submitSelectedTemplate = (templateId) =>
  api()
    .get(`api/wpbox/getTemplateDetails?id=${templateId}`)
    .then(handleResponse)
    .catch(handleError);

export const getImageUrl = (data) =>
  api()
    .post(`api/wpbox/storeFiles`, data)
    .then(handleResponse)
    .catch(handleError);

export const getAttributDropDownData = (companyId) =>
  api()
    .get(`api/wpbox/getAttributes?company_id=${companyId}`)
    .then(handleResponse)
    .catch(handleError);
export const getTagsDropDownData = (companyId) =>
  api()
    .get(`api/wpbox/getTags?company_id=${companyId}`)
    .then(handleResponse)
    .catch(handleError);
export const submitNewTagData = (data) =>
  api()
    .post(`api/wpbox/createTags`, data)
    .then(handleResponse)
    .catch(handleError);

export const getFormDropdownData = (companyId) =>
  api()
    .get(`api/wpbox/forms?company_id=${companyId}`)
    .then(handleResponse)
    .catch(handleError);

export const getCatalogueData = (companyId) =>
  api()
    .get(`api/wpbox/catalogues?company_id=${companyId}`)
    .then(handleResponse)
    .catch(handleError);

export const getRetailerData = (companyId, catalogueId) =>
  api()
    .get(
      `api/wpbox/products?company_id=${companyId}&catalogue_id=${catalogueId}`
    )
    .then(handleResponse)
    .catch(handleError);

export const getAppointmentFormData = (companyId) =>
  api()
    .get(`api/wpbox/appointment-forms?company_id=${companyId}`)
    .then(handleResponse)
    .catch(handleError);
export const getTemplateOrderData = (companyId) =>
  api()
    .get(`api/wpbox/order-templates?company_id=${companyId}`)
    .then(handleResponse)
    .catch(handleError);
