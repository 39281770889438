import React, { useState } from "react";
import { loginBack, iconDemo } from "helper/constant";
import { Modal } from "reactstrap";

import { compose } from "redux";
import { useNavigate, withRouter } from "react-router-dom";
import { registerApi } from "../../../services/authServices";
import enhancer from "../../../enhancers/RegisterFormEnhancer";
import RoleActions from "../../../redux/auth/actions";
import OtpModal from "components/modal/OtpModal";
import Loader from "components/Loader";

const { success, error, fetching } = RoleActions;

const Register = props => {
  const navigate = useNavigate();
  const [isRegisterSuccess, setRegisterSuccessFull] = useState(false);
  const [regEmail, setRegEmail] = useState("");
  const [loader, setloader] = useState(false);

  let { values, isValid, handleSubmit, setFieldValue } = props;

  const [imagePreview, setImagePreview] = useState(null);

  const handleRegister = async e => {
    e.preventDefault();
    handleSubmit();
    console.log(isValid, "isValid");
    if (!isValid) {
      return;
    }
    const formData = new FormData();
    formData.append("name", values.firstname);
    formData.append("phone_number", values.phone_number);
    formData.append("email", values.email);
    formData.append("file_image", values.file_image);
    formData.append("password", values.password);
    setloader(true);
    const result = await registerApi(formData);

    if (result.success) {
      success(result.message);
      setloader(false);
      setRegisterSuccessFull(true);
      setRegEmail(result.data.email);
    } else {
      error(result.message);
    }
  };

  const { handleChange, handleBlur, errors, touched, submitCount } = props;

  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleImageUpload = async e => {
    try {
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setImagePreview(reader.result);
          setFieldValue("file_image", reader.result);
        };

        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.log(error, "error while trying to upload image");
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container-fluid" style={loginContainer}>
          <div>
            <div style={{ width: "500px" }} className="form-container">
              <div className="login-title pt-4">
                <b>Register Account </b>
              </div>
              <form className="pa-24">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    id="firstname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="First Name"
                  />
                  <Error field="firstname" />
                </div>

                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    id="phone_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Phone Number"
                  />
                  <Error field="phone_number" />
                </div>

                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control react-form-input"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email"
                  />
                  <Error field="email" />
                </div>

                <div className="form-group">
                  <label>Logo</label>
                  <input
                    type="file"
                    className="form-control react-form-input"
                    id="file_image"
                    onChange={e => {
                      handleImageUpload(e);
                    }}
                    onBlur={handleBlur}
                    placeholder="Image"
                  />
                  <Error field="file_image" />
                </div>
                {imagePreview !== null ? (
                  <div style={{ width: "100px", height: "100px" }}>
                    <img src={imagePreview} />
                  </div>
                ) : null}

                <div className="form-group">
                  <label>Create Password</label>
                  <input
                    type="password"
                    className="form-control react-form-input"
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Create Password"
                  />
                  <Error field="password" />
                </div>

                <div className="form-group">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    className="form-control react-form-input"
                    id="confirm_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Confirm Password"
                  />
                  <Error field="confirm_password" />
                </div>

                <button
                  type="submit"
                  onClick={handleRegister}
                  className="btn btn-primary form-button"
                >
                  Register
                </button>
                <div
                  className="text-center link-label"
                  onClick={() => navigate("/login")}
                >
                  Login ?
                </div>
              </form>
            </div>
          </div>

          {/* // modal code */}

          <Modal isOpen={isRegisterSuccess} backdrop={true}>
            {isRegisterSuccess && (
              <OtpModal
                email={regEmail}
                onClose={() => {
                  setRegisterSuccessFull(false);
                  navigate("/dashboard");
                }}
              />
            )}
          </Modal>
        </div>
      )}
    </>
  );
};

export default compose(enhancer)(Register);
