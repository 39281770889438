import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Handle } from "reactflow";
import ReactSelect from "react-select";

const TestingFormBuilder = ({
  props,
  onTextChange,
  setSelectedNode,
  deleteParentNode,
  setPreviewState,
}) => {
  const [fieldValue, setFieldValue] = useState("");
  const [parentId, setParentId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState();

  const [allValues, setAllValues] = useState([]);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    const dataToSend = {
      text: event.target.value,
    };
    onTextChange(props?.id, dataToSend);
    setFieldValue(event.target.value);
  };
  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  useEffect(() => {
    if (props?.data?.value?.text) {
      setFieldValue(props?.data?.value?.text);
    }
    if (typeof props?.data?.value == "string") {
      console.log("from checking datatype");
      const dataToSend = {
        text: props?.data?.value,
      };
      setFieldValue(props?.data?.value);
      onTextChange(props?.id, dataToSend);
    }
  }, []);

  const handleClick = (index, event) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const handleAddScreen = () => {
    try {
      handleClose();

      let newScreen = {
        index: allValues.length + 1,
        indexing: [],
        textFields: [],
        dropDowns: [],
        radioButtons: [],
        checkBoxs: [],
        subHeadings: [],
      };

      setAllValues((prevScreens) => [...prevScreens, newScreen]);
    } catch (error) {
      console.log(error, "Error while trying to add a screen");
    }
  };

  const handleAddContent = (index, type, obj) => {
    try {
      handleClose();
      console.log(index, "temppppppppppppppppp inside handle");
      const screenIndex = allValues.findIndex((x) => x?.index == index);

      console.log(allValues[screenIndex], "change in all values aaaa");

      let temp = Object.assign([], allValues);

      temp[screenIndex][type] = [...temp[screenIndex][type], obj];

      console.log(temp, "temppppppppppppppppp");
      const anotherTemp = {
        index: temp[screenIndex]?.indexing.length + 1,
        type,
        localIndex: obj?.index - 1,
        screenIndex,
      };
      temp[screenIndex]["indexing"] = [
        ...temp[screenIndex]?.indexing,
        anotherTemp,
      ];
      setAllValues(temp);

      console.log(anotherTemp, "change in all values anothertemp");
    } catch (error) {
      console.log(error, "Error while trying to add content to screen");
    }
  };

  useEffect(() => {
    console.log(allValues, "change in all values");
  }, [allValues]);

  const handleOnChange = (obj) => {
    try {
      console.log(obj, "change in all values obj");
      setAllValues(obj);
    } catch (error) {
      console.log(error, "Error while trying to change data");
    }
  };

  const inputTypes = [
    { value: "number", label: "Number" },
    { value: "text", label: "Text" },
    { value: "textArea", label: "Text Area" },
    { value: "date", label: "Date" },
  ];

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const handleRemoveOptionsField = (field, id) => {
    try {
      console.log({ field });
      console.log({ id });
      const optionsIndex = allValues[field?.screenIndex][field?.type][
        field?.localIndex
      ]["options"].findIndex((x) => x.id == id);

      console.log({ optionsIndex });
      let temp = Object.assign([], allValues);

      let data =
        temp[field?.screenIndex][field?.type][field?.localIndex]["options"];

      console.log(data, "data before");
      const anotherTemp = data
        .slice(0, optionsIndex)
        .concat(data.slice(optionsIndex + 1));
      console.log(anotherTemp, "data after");

      temp[field?.screenIndex][field?.type][field?.localIndex] = {
        ...temp[field?.screenIndex][field?.type][field?.localIndex],
        options: anotherTemp,
      };

      setAllValues(temp);
    } catch (error) {
      console.log(error, "error while trying to remove options field");
    }
  };

  const handleAddOptionsField = (field) => {
    try {
      console.log({ field });
      let temp = Object.assign([], allValues);

      const newIndex =
        temp[field?.screenIndex][field?.type][field?.localIndex]["options"]
          .length + 1;

      console.log({ newIndex });
      let newOption = {
        id: newIndex,
        title: "",
      };
      let data = [
        ...temp[field?.screenIndex][field?.type][field?.localIndex]["options"],
        newOption,
      ];

      console.log(data, "data before");

      temp[field?.screenIndex][field?.type][field?.localIndex] = {
        ...temp[field?.screenIndex][field?.type][field?.localIndex],
        options: data,
      };

      setAllValues(temp);
    } catch (error) {
      console.log(error, "error while trying to add options field");
    }
  };
  return (
    <>
      <div className="text-updater-node flow-comn-box" onClick={() => {}}>
        <div
          className="flow-comn-close-btn"
          onClick={handleDeleteParentNode}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-times-circle"></i>
        </div>
        <div className="flow-title-box">
          <aside>Message</aside>
          <Handle type="target" className="flow-trigger flow-trigger-fill" />
          <div
            onClick={() => {
              console.log("asdasdas");
              console.log(props?.id, "props.id");
              setSelectedNode(props?.id);
              setPreviewState(true);
            }}
            className="preview-eye-button"
          >
            <i className="fas fa-eye"></i>
          </div>
        </div>
        {/* ---------------------------------Screens Map-------------------------------------------- */}
        {allValues?.length > 0 &&
          allValues.map((x, i) => {
            return (
              <>
                {`Screen ${x?.index} `}
                {x?.indexing.length > 0 &&
                  x?.indexing?.map((field, indexOfField) => {
                    return (
                      <>
                        {field?.type == "textFields" ? (
                          <>
                            <div style={{ marginTop: "20px" }}>
                              <label htmlFor={`indexing=${indexOfField}`}>
                                Enter Name of the field
                              </label>
                              <TextField
                                className="w-100"
                                id={`indexing-${indexOfField}`}
                                multiline
                                placeholder="Enter Name for your field"
                                value={
                                  allValues[field?.screenIndex][field?.type][
                                    field?.localIndex
                                  ]?.name
                                }
                                onChange={(e) => {
                                  let temp = Object.assign([], allValues);
                                  temp[field?.screenIndex][field?.type][
                                    field?.localIndex
                                  ] = {
                                    ...temp[field?.screenIndex][field?.type][
                                      field?.localIndex
                                    ],
                                    name: e.target.value,
                                  };
                                  handleOnChange(temp);
                                }}
                              />
                            </div>

                            <div>
                              <label
                                htmlFor={`indexing-${indexOfField}-inputType`}
                              >
                                Select input type
                              </label>
                              <select
                                className="w-100"
                                id={`indexing-${indexOfField}-inputType`}
                                value={
                                  allValues[field?.screenIndex][field?.type][
                                    field?.localIndex
                                  ]?.inputType
                                }
                                onChange={(e) => {
                                  console.log(e.target.value);

                                  let temp = Object.assign([], allValues);
                                  temp[field?.screenIndex][field?.type][
                                    field?.localIndex
                                  ] = {
                                    ...temp[field?.screenIndex][field?.type][
                                      field?.localIndex
                                    ],
                                    inputType: e.target.value,
                                  };
                                  handleOnChange(temp);
                                }}
                                options={options}
                                placeholder="Select type"
                              >
                                {" "}
                                <option value="text">Text</option>{" "}
                                <option value="number">Number</option>
                                <option value="textArea">Text Area</option>
                                <option value="date">Date</option>
                                {/* <MenuItem value={"text"}>Text</MenuItem> */}
                              </select>
                            </div>
                          </>
                        ) : field?.type == "radioButtons" ||
                          field?.type == "checkBoxs" ||
                          field?.type == "dropDowns" ? (
                          <>
                            <div style={{ marginTop: "20px" }}>
                              <label htmlFor={`indexing=${indexOfField}`}>
                                Enter Name of the field
                              </label>
                              <TextField
                                className="w-100"
                                id={`indexing-${indexOfField}`}
                                multiline
                                placeholder="Enter Name for your field"
                                value={
                                  allValues[field?.screenIndex][field?.type][
                                    field?.localIndex
                                  ]?.name
                                }
                                onChange={(e) => {
                                  let temp = Object.assign([], allValues);
                                  temp[field?.screenIndex][field?.type][
                                    field?.localIndex
                                  ] = {
                                    ...temp[field?.screenIndex][field?.type][
                                      field?.localIndex
                                    ],
                                    name: e.target.value,
                                  };
                                  handleOnChange(temp);
                                }}
                              />
                            </div>
                            <div>
                              {allValues[field?.screenIndex][field?.type][
                                field?.localIndex
                              ]?.options?.length > 0 &&
                                allValues[field?.screenIndex][field?.type][
                                  field?.localIndex
                                ]?.options.map((options, indexOfOptions) => {
                                  // const data = [
                                  //   {
                                  //     ...temp[field?.screenIndex][field?.type][
                                  //       field?.localIndex
                                  //     ]["options"],
                                  //     title: e.target.value,
                                  //   },
                                  // ];
                                  return (
                                    <div>
                                      <button
                                        onClick={() => {
                                          console.log(
                                            options,
                                            "options in options"
                                          );
                                          handleRemoveOptionsField(
                                            field,
                                            options?.id
                                          );
                                        }}
                                      >
                                        Remove
                                      </button>
                                      {`Enter Label for  ${
                                        field?.type == "radioButtons"
                                          ? " Radio Button"
                                          : field?.type == "checkBoxs"
                                          ? "Check Box"
                                          : field.type == "dropDowns"
                                          ? "Drop down option"
                                          : null
                                      } ${indexOfOptions + 1}`}

                                      <TextField
                                        className="w-100"
                                        id={`indexing-${indexOfField}`}
                                        multiline
                                        placeholder="Enter Label for Radio"
                                        value={options.title}
                                        onChange={(e) => {
                                          let temp = Object.assign(
                                            [],
                                            allValues
                                          );
                                          const data = [
                                            ...temp[field?.screenIndex][
                                              field?.type
                                            ][field?.localIndex]["options"],
                                            // options[indexOfOptions].title : e.target.value
                                            // title: e.target.value,
                                          ];
                                          data[indexOfOptions]["title"] =
                                            e.target.value;
                                          temp[field?.screenIndex][field?.type][
                                            field?.localIndex
                                          ] = {
                                            ...temp[field?.screenIndex][
                                              field?.type
                                            ][field?.localIndex],
                                            options: data,
                                          };
                                          handleOnChange(temp);
                                        }}
                                      ></TextField>
                                    </div>
                                  );
                                })}
                              <Button
                                onClick={() => {
                                  handleAddOptionsField(field);
                                }}
                              >
                                {`Add ${
                                  field?.type == "radioButtons"
                                    ? " Radio Button"
                                    : field?.type == "checkBoxs"
                                    ? "Check Box"
                                    : field.type == "dropDowns"
                                    ? "Drop down option"
                                    : null
                                } `}
                              </Button>
                            </div>
                          </>
                        ) : field?.type == "subHeadings" ? (
                          <>
                            <div style={{ marginTop: "20px" }}>
                              <label htmlFor={`indexing=${indexOfField}`}>
                                Enter Text for Sub heading
                              </label>
                              <TextField
                                className="w-100"
                                id={`indexing-${indexOfField}`}
                                multiline
                                placeholder="Enter Text"
                                value={
                                  allValues[field?.screenIndex][field?.type][
                                    field?.localIndex
                                  ]?.name
                                }
                                onChange={(e) => {
                                  let temp = Object.assign([], allValues);
                                  temp[field?.screenIndex][field?.type][
                                    field?.localIndex
                                  ] = {
                                    ...temp[field?.screenIndex][field?.type][
                                      field?.localIndex
                                    ],
                                    name: e.target.value,
                                  };
                                  handleOnChange(temp);
                                }}
                              />
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })}
                <div>
                  <Button
                    id="basic-button2"
                    aria-controls={open ? "basic-menu2" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => handleClick(x?.index, e)}
                    className="flow-comn-btn"
                  >
                    Add Content
                  </Button>
                  <Menu
                    id="basic-menu2"
                    anchorEl={anchorEl}
                    open={selectedIndex == x?.index ? true : false}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button2",
                    }}
                  >
                    <div style={{ height: "100%", width: "200px" }}>
                      <MenuItem
                        onClick={() => {
                          handleAddContent(x?.index, "textFields", {
                            index: allValues[i]?.textFields?.length + 1,
                            name: "aaaaa",
                            inputType: "",
                          });
                        }}
                      >
                        Add Text Field
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          console.log(
                            x?.index,
                            "temppppppppppppppppp index",
                            allValues,
                            ">>",
                            allValues[i]?.radioButtons?.length + 1
                          );
                          handleAddContent(x?.index, "radioButtons", {
                            index: allValues[i]?.radioButtons?.length + 1,
                            name: "",
                            options: [
                              { id: 0, title: "" },
                              { id: 1, title: "" },
                            ],
                          });
                        }}
                      >
                        Add Radio Button
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleAddContent(x?.index, "checkBoxs", {
                            index: allValues[i]?.checkBoxs?.length + 1,
                            name: "",
                            options: [
                              { id: 0, title: "" },
                              { id: 1, title: "" },
                            ],
                          });
                        }}
                      >
                        Add CheckBox
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleAddContent(x?.index, "dropDowns", {
                            index: allValues[i]?.dropDowns?.length + 1,
                            name: "",
                            options: [
                              { id: 0, title: "" },
                              { id: 1, title: "" },
                            ],
                          });
                        }}
                      >
                        Add Drop down
                      </MenuItem>{" "}
                      <MenuItem
                        onClick={() => {
                          handleAddContent(x?.index, "subHeadings", {
                            index: allValues[i]?.subHeadings?.length + 1,
                            name: "",
                          });
                        }}
                      >
                        Add Sub Heading
                      </MenuItem>
                    </div>
                  </Menu>
                </div>
              </>
            );
          })}

        <div>
          {" "}
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleAddScreen}
            className="flow-comn-btn"
          >
            Add Screen
          </Button>
        </div>
      </div>
    </>
  );
};

export default TestingFormBuilder;
