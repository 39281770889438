import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Handle, useStore, Position } from "reactflow";

import Enhancer from "../Enhancers/SingleProductEnhancer";
import subscriptionAction from "redux/subscription/action";
import { getCatalogueData, getRetailerData } from "services/flowServices";

const connectionNodeIdSelector = (state) => state.connectionNodeId;
const TestingSingleProductNode = ({
  props,
  onTextChange,
  deleteParentNode,
  setPreviewState,
  duplicateNode,
  setSelectedNode,
  setValues,
  errors,
  touched,
  values,
  submitCount,
  setErrorsArray,
  handleBlur,
  companyId,
}) => {
  const { success, error, fetching } = subscriptionAction;

  const [parentId, setParentId] = useState();
  const [allValues, setAllValues] = useState({
    body: "",
    footer: "",
    retailerId: {
      id: "",
      mediaUrl: "",
      name: "",
      description: "",
      retailerId: "",
      price: "",
    },
    catalogueId: "",
  });

  const [catalogueDropdownOptions, setCatalogueDropdownOptions] = useState([]);
  const [retaileDropdownOptions, setRetailDropdownOptions] = useState([]);

  useEffect(() => {
    console.log(errors, "if any from formik");
    // console.log(touched, "touched if any from formik  ");
    if (Object.keys(errors).length !== 0) {
      // console.log(errors, "errors array single if");
      setErrorsArray((arr) => {
        const index = arr.findIndex((x) => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      // console.log(errors, "errors array single else");
      setErrorsArray((arr) => {
        const filteredArr = arr.filter((x) => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [errors]);

  useEffect(() => {
    setValues(allValues);
    onTextChange(props?.id, allValues);
    console.log(allValues, "all values in single");
  }, [allValues]);

  useEffect(() => {
    if (companyId) {
      handleGetCatalogueData(companyId);
    }
  }, [companyId]);

  const handleGetCatalogueData = async (companyId) => {
    try {
      const res = await getRetailerData(companyId);
      console.log(res, "Res here");
      if (res.status == "success") {
        console.log("success");

        let dropdownArray = [];
        if (res?.data?.length > 0) {
          let id = res?.data[0].catalogue_id;
          res?.data?.map((option, index) => {
            // let obj = {
            //   name: option.name,
            //   id: option.id,
            // };

            dropdownArray.push(option);
          });
        } else {
          let obj = {
            name: "Noting To Select",
            id: null,
          };

          dropdownArray.push(obj);
        }

        setRetailDropdownOptions(dropdownArray);
        if (res?.data?.length > 0 && res?.data[0].id) {
          const idToSet = res?.data[0].catalogue_id;
          // handleGetRetailerDetails(idToSet);
          const temp = { ...allValues };
          temp.catalogueId = idToSet;
          setAllValues((prevValues) => {
            prevValues.catalogueId = idToSet;
            console.log({ prevValues });
            return prevValues;
          });
        }
      }
    } catch (error) {
      console.log(error, "Error while trying to handleGetCatalogueData");
    }
  };

  // useEffect(() => {
  //   if (props?.id) {
  //     setParentId(extractParentId(props?.id));
  //   }
  //   if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
  //     console.log("Setting data here", props?.data?.value);
  //     let temp = { ...props?.data?.value };
  //     temp.retailerId = props?.data?.value?.retailerId;
  //     setAllValues(temp);
  //     setValues(temp);
  //     onTextChange(props?.id, temp);
  //   } else {
  //     setValues(allValues);
  //   }
  // }, []);
  useEffect(() => {
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      console.log("Setting data here", props?.data?.value);
      const { body, footer, retailerId, catalogueId } = props?.data?.value;
      const temp = {
        body: body || "",
        footer: footer || "",
        retailerId: {
          id: retailerId?.id || "",
          mediaUrl: retailerId?.mediaUrl || "",
          name: retailerId?.name || "",
          description: retailerId?.description || "",
          retailerId: retailerId?.retailerId || "",
          price: retailerId?.price || "",
        },
        catalogueId: catalogueId || "",
      };
      setAllValues(temp);
      setValues(temp);
      onTextChange(props?.id, temp);
    }
  }, []);

  function extractParentId(childId) {
    const parts = childId.split("-");
    if (parts.length > 1) {
      return parts[0];
    } else {
      return childId;
    }
  }

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;
  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  const Error = (props) => {
    const field1 = props.field;
    if (errors[field1] || touched[field1] || submitCount > 0) {
      // console.log("inside if any from formik");
      return (
        <>
          {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
          {/* </p> */}
          <aside className="error-message-row">
            <span className={props.class ? props.class : "error-msg"}>
              {errors[field1]}
            </span>
          </aside>
        </>
      );
    } else {
      return <span />;
    }
  };
  // console.log(allValues, "all values inside sp node");
  const handleChange = (id, value) => {
    let maxLength;

    if (id === "body") {
      maxLength = 1024;
    } else if (id === "footer") {
      maxLength = 60;
    }

    let newVal = value;
    if (newVal?.length > maxLength) {
      error(`Only ${maxLength} characters are allowed in ${id}`);
      newVal = newVal.slice(0, maxLength);
    }

    const temp = {
      ...allValues,
      [id]: newVal,
    };
    setAllValues(temp);
    setValues(temp);
    onTextChange(props?.id, temp);
  };

  const handleCatalogueSelect = (option) => {
    const temp = {
      ...allValues,
      catalogueId: option.target.value,
      retailerId: {
        id: "",
        mediaUrl: "",
        name: "",
        description: "",
        retailerId: "",
        price: "",
      },
    };
    setAllValues(temp);
    handleGetRetailerDetails(option.target.value);
  };

  const handleRetailSelect = (option) => {
    const retailObject = retaileDropdownOptions.find(
      (x) => x.id === option?.target?.value
    );

    const temp = {
      ...allValues,
      retailerId: {
        id: retailObject?.id || "",
        mediaUrl: retailObject?.image_url || "",
        name: retailObject?.name || "",
        description: retailObject?.description || "",
        retailerId: retailObject?.retailer_id || "",
        price: retailObject?.price || "",
      },
    };

    setAllValues(temp);
  };

  const handleGetRetailerDetails = async (catalogueId) => {
    try {
      const res = await getRetailerData(companyId, catalogueId);

      console.log(res, "Res here");
      if (res.status == "success") {
        setRetailDropdownOptions(res?.data);
      }
    } catch (error) {
      console.log(error, "Error while trying to handleGetRetailerDetails");
    }
  };
  return (
    <>
      <div className="text-updater-node flow-comn-box">
        <div className="flow-title-box">
          <aside>Message</aside>
          {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
          {props?.type !== "Template" ? (
            <>
              <div className="header-btn-row">
                <Tooltip title="Preview">
                  <div
                    onClick={() => {
                      setSelectedNode(props?.id);
                      setPreviewState(true);
                    }}
                    className="preview-eye-button"
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </Tooltip>

                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i class="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i class="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>

        <div className="flow-inner-row flow-textarea-row">
          {["body", "footer"].map((field) => {
            return (
              <>
                <aside>
                  <TextField
                    className="textfield-padding-small"
                    size="large"
                    multiline
                    placeholder={
                      field == "footer" ? `${field}(optional)` : `${field}`
                    }
                    minRows={field == "body" ? 4 : 1}
                    key={field}
                    value={
                      allValues && allValues[field] ? allValues[field] : ""
                    }
                    onChange={(e) => handleChange(field, e.target.value)}
                  />
                  <Error field={field} />
                  <div className="counter-class">
                    {allValues[field]?.length !== 0
                      ? field == "body"
                        ? `${allValues[field]?.length}/1024`
                        : field == "footer"
                        ? `${allValues[field]?.length}/60`
                        : null
                      : null}
                  </div>
                </aside>
              </>
            );
          })}
          {/* <div
            style={{
              margin: "9px 0px 1px",
              padding: "3px",
              backgroundColor: "white",
            }}
          >
            {" "}
            Catalogue
          </div> */}
          {/* <Select
            size="small"
            className="w-100 nodrag dropdown-zindex"
            labelId={`demo-simple-select-label-${parentId}`}
            id="demo-simple-select"
            value={allValues?.catalogueId}
            label="Catalogue id"
            placeholder="Enter label"
            onChange={(e) => handleCatalogueSelect(e)}
          >
            {catalogueDropdownOptions && catalogueDropdownOptions.length > 0 ? (
              catalogueDropdownOptions.map((option) => {
                return <MenuItem value={option?.id}>{option?.name}</MenuItem>;
              })
            ) : (
              <MenuItem disabled>Nothing To Select</MenuItem>
            )}
          </Select> */}
          <div
            style={{
              margin: "9px 0px 1px",
              padding: "3px",
              backgroundColor: "white",
            }}
          >
            {" "}
            Product
          </div>{" "}
          <Select
            size="medium"
            className="w-100 nodrag dropdown-zindex"
            labelId={`demo-simple-select-label-${parentId}`}
            id="demo-simple-select"
            value={allValues?.retailerId.id}
            label="Age"
            // renderValue={(selected) => (
            //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            //     {selected.map((value) => (
            //       <Chip key={value} label={value} />
            //     ))}
            //   </Box>
            // )}
            onChange={(e) => handleRetailSelect(e)}
          >
            {retaileDropdownOptions && retaileDropdownOptions.length > 0 ? (
              retaileDropdownOptions.map((option) => {
                return <MenuItem value={option?.id}>{option?.name}</MenuItem>;
              })
            ) : (
              <MenuItem disabled>Nothing To Select</MenuItem>
            )}
          </Select>
          {allValues.retailerId.id && allValues.retailerId.id !== "" && (
            <>
              <div className="data-card">
                <span className="data-card-heading">
                  {" "}
                  {allValues?.retailerId?.name}
                </span>
                <img src={allValues?.retailerId?.mediaUrl} />
                <span style={{ display: "block", marginTop: "10px" }}>
                  {allValues?.retailerId?.price}
                </span>
                <span style={{ display: "block", marginTop: "10px" }}>
                  {allValues?.retailerId?.description}
                </span>
              </div>
            </>
          )}
        </div>
        {isConnecting ? (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger "
              style={{
                overflow: "hidden",
                width: "100%", // Adjust the width as needed
                height: "100%",
                // height: "110%",
                background: isConnecting ? "transparent" : "transparent",
                border: "none",
                position: "absolute",
                left: "0px", // Adjust position to the left side of the node
                top: "50%",
                zIndex: 9,
                borderRadius: 0,
                cursor: isConnecting ? "default" : "move", // Change cursor based on connection state
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger flow-trigger-fill"
              style={{
                left: "1px",
                right: "auto",
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        )}
      </div>
    </>
  );
};

export default Enhancer(TestingSingleProductNode);
