import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    imageLink: Yup.string().required("Media required"),
    body: Yup.string().required("Body is required"),
    buttons: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("Button Title is required"),
      })
    ),
  }),
  mapPropsToValues: (props) => ({
    imageLink: "",
    body: "",
    buttons: [],
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
