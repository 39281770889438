import { api, handleResponse, handleError } from "./apiServices";

export const forgotPassword = data =>
  api()
    .post("/api/taxi/auth/forgotPassword", data)
    .then(handleResponse)
    .catch(handleError);

export const resetPassword = data =>
  api()
    .post(`/api/taxi/auth/changeForgotPassword`, data)
    .then(handleResponse)
    .catch(handleError);

// export const change_password = (token, id, data) =>
//   api(token)
//     .post(`/api/merchant/auth/change-password`, data)
//     .then(handleResponse)
//     .catch(handleError);
