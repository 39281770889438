import React, { useEffect, useState } from "react";
import { Grid, InputLabel, TextField, Tooltip } from "@mui/material";
import { Handle, useStore, Position } from "reactflow";
import Select from "react-select";

import Enhancer from "../Enhancers/FormNodeEnhancer";
import subscriptionAction from "redux/subscription/action";
import {
  getFormDropdownData,
  getTagsDropDownData,
} from "services/flowServices";

const connectionNodeIdSelector = (state) => state.connectionNodeId;
const FormNode = ({
  props,
  onTextChange,
  deleteParentNode,
  setPreviewState,
  duplicateNode,
  setSelectedNode,
  setValues,
  errors,
  touched,
  values,
  submitCount,
  setErrorsArray,
  companyId,
  handleBlur,
}) => {
  const { success, error, fetching } = subscriptionAction;

  const [parentId, setParentId] = useState();
  const [allValues, setAllValues] = useState({
    footer: "",
    body: "",
    header: "",
    buttonTitle: "",
    formId: "",
  });
  const [dropdownOptions, setDropDownOptions] = useState([]);

  useEffect(() => {
    // console.log(errors, "if any from formik");
    // console.log(touched, "touched if any from formik  ");
    if (Object.keys(errors).length !== 0) {
      // console.log(errors, "errors array single if");
      setErrorsArray((arr) => {
        const index = arr.findIndex((x) => x?.id === props?.id);
        if (index === -1) {
          return [...arr, { id: props?.id }];
        }
        return arr; // If the error for this component already exists in the array, no need to update
      });
    } else {
      // console.log(errors, "errors array single else");
      setErrorsArray((arr) => {
        const filteredArr = arr.filter((x) => x?.id !== props?.id);
        return [...filteredArr]; // Return a new array without the error for the current component
      });
    }
  }, [errors]);
  useEffect(() => {
    if (props?.id) {
      setParentId(extractParentId(props?.id));
    }
    if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
      setAllValues(props?.data?.value);
      setValues(props?.data?.value);
    } else {
      setValues(allValues);
    }
  }, []);

  function extractParentId(childId) {
    const parts = childId.split("-");
    if (parts.length > 1) {
      return parts[0];
    } else {
      return childId;
    }
  }

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;
  const handleDeleteParentNode = () => {
    deleteParentNode(parentId);
  };

  const handleChange = (id, value) => {
    // const updatedFields = textFields.map((field) =>
    //   field.id === id ? { ...field, value } : field
    // );
    let maxLength;

    if (id == "body") {
      maxLength = 1024;
    } else if (id == "footer") {
      maxLength = 60;
    }

    let newVal = value;
    if (newVal?.length > maxLength) {
      error(`Only ${maxLength} characters are allowed in ${id}`);
      newVal = newVal.slice(0, maxLength);
    }
    const temp = {
      ...allValues,
      [id]: newVal,
    };
    onTextChange(props?.id, temp);
    setValues(temp);
    setAllValues(temp);
  };

  const Error = (props) => {
    const field1 = props.field;
    if (errors[field1] || touched[field1] || submitCount > 0) {
      // console.log("inside if any from formik");
      return (
        <>
          {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
          {/* </p> */}
          <aside className="error-message-row">
            <span className={props.class ? props.class : "error-msg"}>
              {errors[field1]}
            </span>
          </aside>
        </>
      );
    } else {
      return <span />;
    }
  };
  const handleGetDropdownData = async () => {
    try {
      const res = await getFormDropdownData(companyId);
      if (res.status == "success") {
        // console.log(res, "res from dropdown api");

        if (res?.data?.length > 0) {
          let masterArray = [];
          res?.data?.map((x) => {
            let temp = {
              value: x?.flow_id,
              label: x?.name,
            };
            masterArray.push(temp);
          });

          setDropDownOptions(masterArray);
        } else {
          let temp = {
            id: null,
            label: "Nothing to select",
          };

          setDropDownOptions(temp);
        }
      } else {
        // console.log(res, "So    mething went wrong");
      }
    } catch (error) {
      console.log(error, "error while trying to get data for dropdown");
    } finally {
      //   setRefresh(false);
    }
  };

  useEffect(() => {
    handleGetDropdownData();
  }, []);
  // console.log(allValues, "all values inside sp node");
  return (
    <>
      <div className="text-updater-node flow-comn-box">
        <div className="flow-title-box">
          <aside>Message</aside>
          <>
            <div className="header-btn-row" style={{ paddingRight: "15px" }}>
              <Tooltip title="Clone">
                <div
                  onClick={() => {
                    duplicateNode(props?.id);
                  }}
                  className="preview-clone-button"
                >
                  <i class="fas fa-clone"></i>{" "}
                </div>
              </Tooltip>
              <Tooltip title="Delete">
                <div
                  onClick={handleDeleteParentNode}
                  className="preview-clone-button"
                >
                  <i class="fas fa-trash"></i>{" "}
                </div>
              </Tooltip>
            </div>
            <Handle
              id={`${props?.id}_attribute`}
              type="source"
              className="flow-trigger"
            ></Handle>
          </>
        </div>

        <div className="flow-inner-row flow-textarea-row">
          {["header", "body", "footer", "buttonTitle"].map((field) => {
            return (
              <>
                <aside>
                  <TextField
                    className="textfield-padding-small"
                    size="large"
                    multiline
                    placeholder={
                      field == "footer" ? `${field}(optional)` : `${field}`
                    }
                    minRows={
                      field == "body" ? 4 : field == "buttonTitle" ? 2 : 1
                    }
                    key={field}
                    value={
                      allValues && allValues[field] ? allValues[field] : ""
                    }
                    onChange={(e) => handleChange(field, e.target.value)}
                  />
                  <Error field={field} />
                  <div className="counter-class">
                    {allValues[field]?.length !== 0
                      ? field == "body"
                        ? `${allValues[field]?.length}/1024`
                        : field == "footer"
                        ? `${allValues[field]?.length}/60`
                        : null
                      : null}
                  </div>
                </aside>
              </>
            );
          })}

          <div>
            <div className="flow-inner-box pb-4">
              <InputLabel id={`demo-simple-select-label-${parentId}`}>
                Form
              </InputLabel>

              <Select
                name="dropdown"
                // menuIsOpen
                // defaultMenuIsOpen
                options={dropdownOptions}
                // isMulti
                value={allValues?.formId}
                onChange={(e) => {
                  console.log(e);
                  if (e?.label && e?.value) {
                    setValues({ ...allValues, formId: e });
                    setAllValues({ ...allValues, formId: e });
                    onTextChange(props?.id, {
                      ...allValues,
                      formId: e,
                    });
                  } else {
                    setValues({ ...allValues, formId: e });
                    setAllValues({ ...allValues, formId: "" });
                    onTextChange(props?.id, { ...allValues, formId: "" });
                  }
                }}
                className="nodrag dropdown-zindex"
                placeholder="Select Data"
              ></Select>
              <Error field={"formId"} />
            </div>{" "}
          </div>
        </div>
        {isConnecting ? (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger "
              style={{
                overflow: "hidden",
                width: "100%", // Adjust the width as needed
                height: "100%",
                // height: "110%",
                background: isConnecting ? "transparent" : "transparent",
                border: "none",
                position: "absolute",
                left: "0px", // Adjust position to the left side of the node
                top: "50%",
                zIndex: 9,
                borderRadius: 0,
                cursor: isConnecting ? "default" : "move", // Change cursor based on connection state
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Left}
              className="flow-trigger flow-trigger-fill"
              style={{
                left: "1px",
                right: "auto",
              }}
              isConnectable={isConnecting} // Disable connection when already connecting
            />
          </>
        )}
      </div>
    </>
  );
};

export default Enhancer(FormNode);
